<template>
    <section class="mw-100 p-2">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Customer Information</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Nama</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.user.fullName }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Email</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.user.account.email }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">No HP</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.user.mobileNumber }}</div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Info Pengiriman</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Info Pengiriman</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-if="data.order_shipping_address">
                      <b>{{data.order_shipping_address.name}}</b><br>
                      {{ data.order_shipping_address ? data.order_shipping_address.address : '-' }}, 
                      {{ data.order_shipping_address ? data.order_shipping_address.destinationArea : '-' }}<br/>
                      No HP: {{ data.order_shipping_address ? data.order_shipping_address.mobileNumber : '-' }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 f-14 text-secondary">Kurir</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">
                      {{ data.order_shipping_address ?  
                          data.order_shipping_address.expedition + ' (' + data.order_shipping_address.shipperReference.rate.name + ')'
                      : '-' }}
                    </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Info Transaksi</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Kode Transaksi</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.orderNumber }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary align-self-center">Status Pesanan</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">
                      <div class="btn btn-sm" :class="{ 
                        'btn-success': data.statusTransaction == 0, 
                        'btn-warning': data.statusTransaction == 1, 
                        'btn-primary': data.statusTransaction == 2, 
                        'btn-danger': data.statusTransaction == 3 
                      }">
                        {{ data.statusTransactionDetail.name }}
                      </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Invoice</div>
                    <div class="col-7 f-14 font-500 text-right pr-1 text-blue">{{ data.invoice }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Tanggal Order</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.createdAt | moment('ll, HH:mm') }}</div>
                </div>
                <div class="row">
                    <div class="col-5 f-14 text-secondary">Pembelian</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ getType(data.types) }}</div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Rincian Pembayaran</p>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Metode Pembayaran</div>
                    <div class="col-6 f-14 font-500 text-right pr-1">
                      {{ data.paymentChannel ? data.paymentChannel.name : '-' }}
                      <div v-if="data.paymentReference">
                        <span v-if="data.paymentReference.bank">, {{data.paymentReference.bank.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.va_numbers"> {{data.paymentReference.va_numbers[0].bank.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.issuer"> {{data.paymentReference.issuer.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.acquirer"> {{data.paymentReference.acquirer.toUpperCase()}}</span>
                      </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Biaya Barang</div>
                    <div class="col-6 f-14 font-500 text-right pr-1">{{ data.subtotal | currency }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Kredit Konsultasi</div>
                    <div v-if="data.creditAmount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -data.creditAmount | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Biaya Pengiriman</div>
                    <div v-if="data.shippingCost > 0" class="col-6 f-14 font-500 text-right pr-1">{{ data.shippingCost | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Discount <span v-if="data.discount > 0">({{data.promoCode}})</span></div>
                    <div v-if="data.discount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ data.discount | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-6 f-14 text-secondary">Total Harga</div>
                    <div class="col-6 f-14 font-500 text-right pr-1 text-danger">{{ data.grandtotal | currency }}</div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-12 col-md-4">
            <div class="card" v-if="data.consult">
              <div class="card-body">
                <p class="f-16 font-600">Info Konsultasi</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Konsul ID</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.consultId }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Chat ID</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.id }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Time to fill</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.createdAt | moment("ll, HH:mm")  }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Submitted Form</div>
                    <div class="col-7 f-14 font-500 text-right pr-1"><span @click="downloadConsult(data.consult.questionnaireReport)" class=" pointer text-blue pull-right"><i class="fa fa-link"></i> Download</span></div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Klinik</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.subClinic }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Kondisi</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row">
                    <div class="col-5 f-14 text-secondary">Media</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-if="data.consult.media && data.consult.media.length > 0">
                      <img alt="" style="width:32px;height:32px;" class="rounded pointer pull-right"
                        v-for="(media, index) in data.consult.media" 
                        :key="index" 
                        :src="media"
                        @click="lihat(media)">
                    </div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-else>-</div>
                </div>
              </div>
            </div>
            
            <div class="card">
              <div class="card-body pb-0">
                  <p class="f-16 font-600">Detail Pembelian</p>
                  <div class="row pr-1 mb-1" v-for="(pr, index) in data.products" :key="index">
                      <div class="col align-self-center">
                          <p class="f-14 font-500 mb-0">
                            {{ pr.title }}
                            <span v-if="pr.deletedAt" class="medicine-label bg-danger">Discontinued</span>
                          </p>
                          <p class="mb-0 text-warning f-12 font-600">{{ pr.order_product.price | currency }} <span class="font-400 text-secondary">({{pr.order_product.quantity | numFormat}} Item )</span></p>
                      </div>
                      <img :src="pr.images.length > 0 ? pr.images[0] : 'https://via.placeholder.com/100'" alt="" style="width: 48px; height: 48px;" class="rounded">
                  </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Payment Info</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Start Payment</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-if="data.paymentReference">{{ data.paymentReference.transaction_time | moment("ll, HH:mm") }}</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-else>-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Expired Payment</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ '-' }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Payment Success</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-if="data.paymentReference && data.paymentReference.settlement_time">{{ data.paymentReference.settlement_time | moment("ll, HH:mm") }}</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-else>-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Metode Pembayaran</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">
                      {{ data.paymentChannel ? data.paymentChannel.name : '-' }}
                      <div v-if="data.paymentReference">
                        <span v-if="data.paymentReference.bank">, {{data.paymentReference.bank.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.va_numbers"> {{data.paymentReference.va_numbers[0].bank.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.issuer"> {{data.paymentReference.issuer.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.acquirer"> {{data.paymentReference.acquirer.toUpperCase()}}</span>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 f-14 text-secondary">Status</div>
                    <div class="col-7 f-14 font-500 text-right pr-1 text-blue">{{ data.paymentStatus }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Notes</p>
                <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Tinggalkan komentar..." v-model="data.notes"></textarea>
                <button class="btn btn-primary mt-1 w-100 rounded" @click="updateNotes()">Update Notes</button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Resi Pengiriman</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Kurir</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.order_shipping_address ? data.order_shipping_address.expedition + ' (' + data.order_shipping_address.shipperReference.rate.name + ')' : '-' }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Resi Shipper</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.shipperId ? data.shipperId : '-' }}</div>
                </div>
                <div class="row">
                    <div class="col-5 f-14 text-secondary">Resi Operator</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.awb ? data.awb : '-' }}</div>
                </div>
                <!-- <div class="row">
                    <div class="col-5 f-14 text-secondary">Status</div>
                    <div class="col-7 f-14 font-500 text-right pr-1 " :class="data.statusDelivery == 3 ? 'text-warning' : 'text-primary' ">{{data.statusDelivery == 3 ? 'SEDANG DIKIRIM' : 'SAMPAI TUJUAN'}}</div>
                </div> -->
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <button class="btn btn-success w-100 rounded" @click="downloadPdf()">Lihat Invoice PDF</button>
                <button class="btn btn-danger w-100 rounded mt-1" @click="updateStatus(2)" v-if="data.statusTransaction == 1 || data.statusTransaction == 2">Refund</button>
              </div>
            </div>

            <div class="card" v-if="trackings.length > 0">
                <div class="card-body">
                    <p class="f-16 font-600">Informasi Pengiriman</p>
                    <div class="row mb-1" v-for="(tracking, index) in trackings" :key="index">
                        <div class="col-6 f-14 text-secondary" style="border-right: 1px solid #ccc;">
                            <p class="f-14 text-primary mb-0 font-600">{{tracking.logistic_status.name}}</p>
                            <p class="f-12 text-secondary font-300 mb-0">{{ tracking.created_date | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                        </div>
                        <div class="col-6 f-14 font-500 pr-1">
                            <p class="f-12 text-secondary font-400 mb-0">
                                {{ tracking.logistic_status.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      id: this.$route.params.id,
      data: {
        user: {
          account: {}
        },
        order_shipping_address: {
          shipperReference: {
            rate: {}
          }
        },
        status: {},
        paymentReference: {},
        paymentChannel: {},
        consult: {},
        statusTransactionDetail: {}
      },
      trackings: [],
    }
  },
  beforeDestroy() {
        let _ = this
        _.$root.$emit('addAdditionalInfo', '')
    },
  mounted() {
    let _ = this
    _.$moment.locale('id')
    _.axios.get('/orders/' + _.id)
      .then(resp => {
        // console.log(resp)
        _.data = resp.data.data

        _.$root.$emit('addAdditionalInfo', _.data.orderNumber)
      })
      .catch(err => {
        console.log(err.response)
      })
    _.getTrackingDetail()
  },
  methods: {
    getType(types) {
        if ( types ) {
            return types.name.join(', ')
        } else {
            return '-'
        }
    },
    updateNotes() {
      let _ = this
      _.axios.put('/orders/'+ _.id +'/notes', {
        notes: _.data.notes
      })
        .then(resp => {
          // console.log(resp)
          _.data = resp.data.data
          _.$swal.fire({
              title: resp.data.message,
              text: 'Notes Updated',
              icon: 'success',
              confirmButtonColor: '#0036A0',
              confirmButtonText: 'OK'
          })
        })
        .catch(err => {
          console.log(err.response)
        })
    },
    downloadPdf(){
      let _ = this

      _.axios.get('/orders/' + _.$route.params.id + '/invoice'
          ,{responseType: 'arraybuffer'})
          .then(resp => {
              let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link        = document.createElement('a')
              link.href       = window.URL.createObjectURL(blob)
              link.download   = 'ORDER-INVOICE-' + _.data.orderNumber + '.pdf'
              link.click()
          })
          .catch(err => {
              console.log(err.response)
          })
    },
    getTrackingDetail() {
        let _ = this
        _.axios.get('orders/' + _.$route.params.id + '/shipping')
            .then(resp => {
                _.trackings = resp.data.data.data.trackings
            })
    },
    updateStatus( statusCode ) {
      let _ = this
      let data =  {
        status: statusCode,
        message: "Your transaction has been rejected, & in process of refund"
      }

      _.axios.put('orders/' + _.id + '/refund', data)
        .then(resp => {
          _.data.status = 2
          this.$notify({
            type: 'success',
            title: 'Success',
            text: resp.data.message
          })
        })
        .catch(err => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message
          })
        })
    },
    lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
    downloadConsult(url) {
        fetch(url, { headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken')}})
            .then(res => res.blob())
            .then(blob => {
                let link        = document.createElement('a')
                link.href       = window.URL.createObjectURL(blob)
                link.download   = this.data.consult.consultId + '.pdf'
                link.click()
            })
    }
  }
}
</script>

<style>
.medicine-label {
    color: white;
    padding: 2px 4px;
    font-size: .75rem;
    margin-left: 8px;
    border-radius: 5px;
}
</style>