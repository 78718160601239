import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import ConsultDetail from '../views/consult/Detail.vue'

// import Undermaintenance from '../views/errors/503.vue'
// import NotFound from '../views/errors/404.vue'
// import Forbidden from '../views/errors/403.vue'

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import EditProfile from '../views/user/EditProfile.vue'
import ChangePassword from '../views/user/ChangePassword.vue'

import TransactionIndex from '../views/transaction/Index'
import TransactionDetail from '../views/transaction/Detail'

import CustomerIndex from '../views/customer/Index.vue'
import CustomerDetail from '../views/customer/Detail.vue'
import CustomerForm from '../views/customer/Form.vue'

import ShippingIndex from '../views/shipping/Index.vue'
import ShippingDetail from '../views/shipping/Detail.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                redirect: '/konsultasi-dan-resep'
            },
            {
                path: '/konsultasi-dan-resep',
                name: 'Home',
                component: Home,
                meta: {
                    title: 'Konsultasi & Resep'
                }
            },
            {
                path: '/user/edit_profile',
                name: 'EditProfile',
                component: EditProfile,
            },
            {
                path: '/user/change_password',
                name: 'ChangePassword',
                component: ChangePassword,
            },
            {
                path: '/daftar-pelanggan',
                name: 'CustomerIndex',
                component: CustomerIndex,
                meta: {
                    title: 'Daftar Pelanggan'
                }
            },
            {
                path: '/daftar-pelanggan/detail/:id',
                name: 'CustomerDetail',
                component: CustomerDetail,
                meta: {
                    title: 'Pelanggan / '
                }
            },
            {
                path: '/daftar-pelanggan/edit/:id',
                name: 'CustomerForm',
                component: CustomerForm,
                meta: {
                    title: 'Edit Profile / '
                }
            },
            {
                path: '/daftar-transaksi',
                name: 'TransactionIndex',
                component: TransactionIndex,
                meta: {
                    title: 'Daftar Transaksi'
                }
            },
            {
                path: '/daftar-transaksi/:id',
                name: 'TransactionDetail',
                component: TransactionDetail,
                meta: {
                    title: 'Detail Transaksi - ID: '
                }
            },
            {
                path: '/apotek-dan-pengiriman',
                name: 'ShippingIndex',
                component: ShippingIndex,
                meta: {
                    title: 'Apotek & Pengiriman'
                }
            },
            {
                path: '/apotek-dan-pengiriman/detail/:id',
                name: 'ShippingDetail',
                component: ShippingDetail,
                meta: {
                    title: 'Detail Transaksi - ID: '
                }
            },
            {
                path: '/konsultasi-dan-resep/:id',
                name: 'ConsultDetail',
                component: ConsultDetail,
                meta: {
                    title: 'Rekam Medis',
                    backButton: true,
                }
            },
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    isGuest: true
                }
            },
            // {
            //     path: '*',
            //     name: 'NotFound',
            //     component: NotFound
            // }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if ( !store.state.accessToken && !localStorage.getItem('accessToken') ) {
        next({name: 'Login'})
    } else {
        next()
    }
})

export default router
