<template>
    <div class="mw-100 p-2">
        <div class="section">
            <div class="card">
                <div class="card-body p-0">
                    <div class="row p-2">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="input-group col-6">
                                    <span class="input-group-prepend">
                                        <div class="input-group-text border-right-0 bg-transparent text-blue">
                                            <i class="fa fa-search"></i>
                                        </div>
                                    </span>
                                    <input class="form-control border-left-0" v-model="search" type="search"
                                        placeholder="Cari user...">
                                    
                                </div>
                                <!-- <p class="align-self-center mb-0 f-14 font-600">Status</p>
                                <div class="col">
                                    <select name="" id="" class="form-control">
                                        <option value="">Semua Status</option>
                                    </select>
                                </div> -->
                            </div>
                        </div>
                        <div class="col text-right">
                            <!-- <div class="btn-responsive">
                                <button type="button" class="btn btn-primary rounded" @click="$router.push('/products/add')">
                                    <i class="fa fa-plus"></i> Tambah User
                                </button>
                            </div> -->
                        </div>
                    </div>   
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th class="font-600 text-black">User ID</th>
                                    <th class="font-600 text-black">Name</th>
                                    <th class="font-600 text-black">Email</th>
                                    <th class="font-600 text-black">Phone</th>
                                    <th class="font-600 text-black">Terakhir Order</th>
                                    <th class="font-600 text-black" style="width: 10px;">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in table.dataTable.lists.rows" :key="index">
                                    <td>{{ data.id }}</td>
                                    <td>{{ data.fullName }}</td>
                                    <td>{{ data.account ? data.account.email : '' }}</td>
                                    <td>{{ data.mobileNumber ? data.mobileNumber : '-' }}</td>
                                    <td v-if="data.lastOrderedAt != '' && data.lastOrderedAt != 'Invalid Date'">{{ data.lastOrderedAt | moment("DD MMM yyyy") }}</td>
                                    <td v-else>-</td>
                                    <td>
                                        <div class="btn-action-block">
                                            <button type="button" class="btn btn-sm p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="material-icons">more_horiz</span>
                                            </button>
                                            <div class="dropdown-menu mr-3">
                                                <a class="dropdown-item text-black" @click="$router.push('/daftar-pelanggan/detail/' + data.id)">Lihat Detail</a>
                                                <!-- <a class="dropdown-item text-black" @click="$router.push('/customer-notes/edit/' + data.id)">Edit Informasi</a> -->
                                                <!-- <div class="dropdown-divider"></div>
                                                <a class="dropdown-item text-blue">Reset Password</a> -->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col text-center mt-1">
                        <p class="f-14" v-if="table.dataTable.lists.count == 0 || !table.dataTable.lists.rows">There is no data</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body pl-3">
                    <PaginateList :data="table.dataTable.lists" v-if="table.dataTable.lists.total != 0"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaginateList from '@/components/PaginateList'
// import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
	components: {
        // Table,
        PaginateList
	},
	data() {
		return {
			table: {
                dataTable: {
                    header: ['User', 'Email', 'Phone', 'Terakhir Order', 'Status', 'Action'],
                    columns: ['user', 'email', 'phone', 'lastOrderedAt', 'status'],
                    lists: [],
                }
			},
            filter: '',
            search: '',
            limit: 10,
            isLoading: false
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/users?sortBy=createdAt.DESC&page=' + (page ? page : 1) + '&limit=' + _.limit + '&search=' + _.search)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete Flow?',
                text: x.fullName,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/products/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>