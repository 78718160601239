export default {
    UPDATE_USER(state, payload) {
		state.accessToken 		= payload.accessToken
    	state.account 			= payload.account

    	localStorage.setItem('accessToken', payload.accessToken)
    	localStorage.setItem('account', JSON.stringify(payload.account))
    },
    LOGOUT_USER(state) {
    	state.accessToken = null
    	state.account = null
    	localStorage.removeItem('accessToken')
		localStorage.removeItem('account')
    }
}