<template>
    <div class="mw-100 p-2">
        <div class="card col-lg-6 col-xs-12 p-0">
            <div class="card-body">
                <ValidationObserver ref="observer">
                    <form @submit.prevent="save()">
                        <h3 class="font-600 mb-2">Ubah Profile</h3>
                        <div class="row">
                            <div class="col-lg-4 col-xs-12 text-center mb-2">
                                <div class="profile-avatar">
                                    <img :src="model.avatar ? model.avatar:'/assets/img/icon/profile.png'" class="img-fluid" alt="">
                                    <div class="edit-avatar">
                                        <label for="file-upload" class="btn-edit">
                                            <i class="fa fa-pencil"></i>
                                        </label>
                                        <input id="file-upload" class="input-file-avatar" type="file" v-on:change="handleFileUpload">
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">Email <span class="text-danger">*</span></label>
                                        <input type="text" v-model="model.email" class="form-control" placeholder="Enter email" disabled>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary required">Nama <span class="text-danger">*</span></label>
                                        <input type="text" v-model="model.fullName" class="form-control" placeholder="Enter nama">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider name="no. telp" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">No. Telepon</label>
                                        <input type="text" v-model="model.mobileNumber" class="form-control" placeholder="Enter telepon">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <hr>
                        <div class="">
                            <router-link to="/" class="btn text-danger">Batal</router-link>
                            <button type="submit" class="btn btn-primary" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                id: null,
                email: "",
                fullName: "",
                mobileNumber: "",
                avatar: "",
                admin: {}
            },
            temp_photo: null,
            isLoading: false
        }
    },
    mounted() {
        let _ = this
        _.me()
    },
    methods: {
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid) {
                let data = {
                    email: _.model.email,
                    firstName: _.model.fullName.split(' ')[0],
                    lastName: _.model.fullName.split(' ').filter( (n,i) => i > 0 ).join(' '),
                    mobileNumber: _.model.mobileNumber,
                    avatar: _.model.avatar
                }
                _.isLoading = true
                _.axios.put('admins/' + _.model.admin.id, data)
                    .then(() => {
                        _.$swal.fire({
                            title: 'Data Saved!',
                            text: 'Profile Updated',
                            icon: 'success',
                            confirmButtonColor: '#0036A0',
                            confirmButtonText: 'OK'
                        })

                        _.isLoading = false
                        _.me()
                        _.$router.push('/')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                        _.isLoading = false
                    })
            }
        },
        me() {
            let _ = this
            _.axios.get('auth/me')
                .then(resp => {
                    let data = resp.data.data
                    _.model.id = data.id
                    _.model.email = data.email
                    _.model.fullName = data.admin.fullName
                    _.model.mobileNumber = data.admin.mobileNumber
                    _.model.avatar = data.admin.avatar
                    _.model.admin = data.admin

                    _.$root.$emit('changeProfile', resp.data.data)
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message,
                    })
                })
        },
        handleFileUpload(event){
            this.file = event.target.files[0]

            let formData = new FormData()
            formData.append('folder', 'avatars')
            formData.append('file', this.file)

            this.axios.post('/misc/upload',
                formData,
                {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
                }).then(resp => {
                    if ( resp.data.success ) {
                      let file = resp.data.data.file
                      this.model.avatar = file
                    } else {
                      this.model.avatar = 'https://via.placeholder.com/150?text=dummy+image'
                    }
                }).catch(err => {
                    console.log(err.response)
                })
          },
    }
}
</script>