import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import VueAxios from 'vue-axios'
import validate from './plugins/validate.js'
import VModal from 'vue-js-modal'
import { ValidationObserver } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'
import VueToast from 'vue-toast-notification'
import VueCurrencyFilter from 'vue-currency-filter'
import VueFormWizard from 'vue-form-wizard'
import vue2Dropzone from 'vue2-dropzone'
import DatePicker from 'vue2-datepicker'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import VueNumericInput from 'vue-numeric-input'
import VueQuillEditor from 'vue-quill-editor'
import VCalendar from 'v-calendar'
import Switches from 'vue-switches'
import VueMoment from 'vue-moment'
import moment from 'moment'
import Geocoder from '@pderas/vue2-geocoder'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueCarousel from 'vue-carousel'
import Notifications from 'vue-notification'
import VueTimepicker from 'vue2-timepicker'
import VueNumeric from 'vue-numeric'
import JsonViewer from 'vue-json-viewer'
import { EmojiPickerPlugin } from 'vue-emoji-picker'
import OneSignalVue from 'onesignal-vue';
import PortalVue from 'portal-vue';
import VueViewer from 'v-viewer'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'vue2-datepicker/index.css'
import 'dropzone/dist/dropzone.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-select/dist/vue-select.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import 'viewerjs/dist/viewer.css'

let user = JSON.parse(localStorage.getItem('account'))

import Pusher from 'pusher-js' 
window.Pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
	cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
	encrypted: false,
	authEndpoint: process.env.VUE_APP_BASE_URL + '/auth/pusher',
	auth: {
		params:{
			accountId: ( user ? user.id : '' ),
			isAdmin: true
		}
	}
});

Vue.use(VueAxios, axios)
Vue.use(VModal)
Vue.use(VueMoment, { moment })
Vue.use(VueFormWizard)
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2)
Vue.use(VueNumericInput)
Vue.use(VueQuillEditor)
Vue.use(VueCarousel)
Vue.use(Notifications)
Vue.use(JsonViewer)
Vue.use(EmojiPickerPlugin)
Vue.use(OneSignalVue)
Vue.use(PortalVue)
Vue.use(VueViewer, {
	defaultOptions: {
		toolbar: false,
		title: false,
		button: false,
	}
})
Vue.use(VueToast, {
  position: 'top'
})

Vue.use(VueCurrencyFilter,[
	{
		name: 'numFormat',
		symbol: '',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
		symbol: 'Rp',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	}
])

Vue.use(VCalendar, {
	componentPrefix: 'vc'
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: 'places',
    },
    installComponents: true
})

Vue.use(Geocoder, {
    defaultCountryCode: null,
    defaultLanguage: null,
    defaultMode: 'lat-lng',
    googleMapsApiKey: process.env.VUE_APP_GOOGLE_KEY
});

Vue.component('DatePicker', DatePicker)
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('multiselect', Multiselect)
Vue.component('vueDropzone', vue2Dropzone)
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect)
Vue.component('switches', Switches)
Vue.component('VueTimepicker', VueTimepicker)
Vue.component('VueNumeric', VueNumeric)
Vue.component('vueDropzone', vue2Dropzone)

Vue.config.productionTip = false
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL

Vue.directive('can', {
	inserted(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (localStorage.getItem('role').indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = '';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (localStorage.getItem('role').indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = '';
			}
		}
	},
	update(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (localStorage.getItem('role').indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = '';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (localStorage.getItem('role').indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = '';
			}
		}
	},
	bind(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (localStorage.getItem('role').indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = '';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (localStorage.getItem('role').indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = '';
			}
		}
	}
});

new Vue({
	store,
	router,
	render: h => h(App),
	mounted() {
		if (localStorage.getItem('token')){
			this.$store.dispatch('tryAutoLogin')
		}
	},
	beforeMount() {
		const ONESIGNAL_APP_ID = process.env.VUE_APP_ONESIGNAL_APP_ID;
		if (ONESIGNAL_APP_ID) {
			const account = JSON.parse(localStorage.getItem('account'));
			const isReadyToSubscribe = account && account.oneSignalAuthHash && account.admin && account.admin.accountId;

			this.$OneSignal.init({ appId: ONESIGNAL_APP_ID })
				.then(() => {
					if (isReadyToSubscribe) {
						this.$OneSignal.showSlidedownPrompt({ force: true })
					}
				});
			
			this.$OneSignal.on('subscriptionChange', (isSubscribed) => {
				if (isSubscribed && isReadyToSubscribe) {
					this.$OneSignal.setExternalUserId(String(account.admin.accountId), account.oneSignalAuthHash);
				}
			});
		}
	}
}).$mount('#app')
