<template>
    <div class="mw-100 p-2">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Info Transaksi</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Kode Transaksi</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.orderNumber }}</div>
                        </div>
                        <div class="row mb-1" v-if="model.statusDelivery == 5">
                            <div class="col-5 f-14 text-secondary">Kode Refund</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.refundId }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary align-self-center">Status Pesanan</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                <div :class="'btn btn-sm ' + statusColor(model.statusDeliveryDetail.code)">{{ model.statusDeliveryDetail.name }}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Invoice</div>
                            <div class="col-7 f-14 font-500 text-right pr-1 text-blue">{{ model.invoice }}</div>
                        </div>
                        <div class="row">
                            <div class="col-5 f-14 text-secondary">Tanggal Order</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.createdAt | moment('ll, HH:mm') }}</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Rincian Pembayaran</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Metode Pembayaran</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                {{ model.paymentChannel ? model.paymentChannel.name : '-' }}
                                <div v-if="model.paymentReference">
                                    <span v-if="model.paymentReference.bank">, {{model.paymentReference.bank.toUpperCase()}}</span>
                                    <span v-else-if="model.paymentReference.va_numbers"> {{model.paymentReference.va_numbers[0].bank.toUpperCase()}}</span>
                                    <span v-else-if="model.paymentReference.issuer"> {{model.paymentReference.issuer.toUpperCase()}}</span>
                                    <span v-else-if="model.paymentReference.acquirer"> {{model.paymentReference.acquirer.toUpperCase()}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary align-self-center">Total Biaya Barang</div>
                            <div v-if="model.subtotal > 0" class="col-7 f-14 font-500 text-right pr-1">{{ model.subtotal | currency }}</div>
                            <div v-else class="col-7 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-6 f-14 text-secondary">Kredit Konsultasi</div>
                            <div v-if="model.creditAmount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -model.creditAmount | currency }}</div>
                            <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Biaya Pengiriman</div>
                            <div v-if="model.shippingCost > 0" class="col-7 f-14 font-500 text-right pr-1">{{ model.shippingCost | currency }}</div>
                            <div v-else class="col-7 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-6 f-14 text-secondary">Discount <span v-if="model.discount > 0">({{model.promoCode}})</span></div>
                            <div v-if="model.discount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ model.discount | currency }}</div>
                            <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-5 f-14 text-secondary">Total Harga</div>
                            <div class="col-7 f-14 font-500 text-right pr-1 text-danger">{{ model.grandtotal | currency }}</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Info Pengiriman</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Info Pengiriman</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                <b>{{model.order_shipping_address.name}}</b><br>
                                {{ model.order_shipping_address ? model.order_shipping_address.address : '-' }}, 
                                {{ model.order_shipping_address ? model.order_shipping_address.destinationArea : '-' }}<br>
                                No HP: {{ model.order_shipping_address ? model.order_shipping_address.mobileNumber : '-' }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 f-14 text-secondary">Ekspedisi Pengiriman</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.order_shipping_address.expedition  + ' (' + model.order_shipping_address.shipperReference.rate.name + ')' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body pb-0" v-if="!model.diagnosis">
                        <p class="f-16 font-600">Detail Pembelian</p>
                        <div class="row pr-1 mb-1" v-for="(data, index) in model.products" :key="index">
                            <div class="col align-self-center">
                                <p class="f-14 font-500 mb-0">{{ data.title }}</p>
                                <p class="mb-0 text-warning f-12 font-600">{{ data.order_product.price | currency }} <span class="font-400 text-secondary">({{data.order_product.quantity | numFormat}} Item )</span></p>
                            </div>
                            <img :src="data.images.length > 0 ? data.images[0] : 'https://via.placeholder.com/100'" alt="" style="width: 48px; height: 48px;" class="rounded">
                        </div>
                    </div>
                    <div class="card-body pb-0" v-else>
                        <p class="f-16 font-600">Detail Pembelian & Aturan Pakai</p>
                        <hr>
                        <div v-if="model.diagnosis.recipes.length > 0">
                            <div class="row">
                                <div class="ml-1 f-20 font-600 text-primary">R/</div>
                                <div class="col align-self-center">
                                    <div class="" v-for="(recipe, index) in model.diagnosis.recipes" :key="index">
                                        <p class="f-14 text-black font-500 mb-0">{{recipe.product.title}} 
                                            <span class="f-12 font-400 text-secondary"> - QTY: {{ recipe.usageAmount }} - {{ recipe.usageInterval }}</span>
                                        </p>
                                        <p>
                                            <span class="f-12 font-600" :class="recipe.product.discountPrice > 0 ? 'strikethrough' : 'text-warning'">{{ recipe.product.price | currency }}</span>
                                            <span class="ml-1 text-warning f-12 font-600" v-if="recipe.product.discountPrice > 0">{{ recipe.product.discountPrice | currency }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr class="mt-0">
                        </div>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">C&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 mb-0" v-html="model.diagnosis.c"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Notes</p>
                        <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Tinggalkan komentar..." v-model="model.notes"></textarea>
                        <button class="btn btn-primary mt-1 w-100 rounded" @click="updateNotes()">Update Notes</button>
                    </div>
                </div>
                <div class="card" v-if="model.statusDelivery == 2">
                    <div class="card-body">
                        <p class="f-16 font-600">Jadwal Pickup</p>
                        <div class="form-group">
                            <date-picker
                                v-model="date"
                                format="DD MMMM YYYY, HH:mm"
                                type="datetime"
                                :input-class="'form-control'"
                                value-type="YYYY-MM-DD HH:mm:ss"
                                placeholder="Pilih tanggal"
                                style="width: 100%;"
                                :time-picker-options="{start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm' }"
                                :disabled-date="disabledBeforeToday"
                                :disabled-time="disableTime">
                            </date-picker>
                        </div>
                        <button class="btn btn-primary w-100 rounded" v-if="model.statusDelivery == 2" @click="updateStatus(3)">Kirim Pesanan</button>
                    </div>
                </div>
                <div class="card" v-if="model.statusDelivery >= 3 && model.statusDelivery != 5">
                    <div class="card-body">
                        <p class="f-16 font-600">Resi Pengiriman</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Kurir</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                {{ model.order_shipping_address.expedition ? 
                                    model.order_shipping_address.expedition + ' (' + model.order_shipping_address.shipperReference.rate.name + ')'
                                : '-' }}
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Resi Shipper</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.shipperId ? model.shipperId : '-' }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Resi Operator</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.awb ? model.awb : '-' }}</div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-5 f-14 text-secondary">Status</div>
                            <div class="col-7 f-14 font-500 text-right pr-1 " :class="model.statusDelivery == 3 ? 'text-warning' : 'text-primary' ">{{model.statusDelivery == 3 ? 'SEDANG DIKIRIM' : 'SAMPAI TUJUAN'}}</div>
                        </div> -->
                        <button class="btn btn-primary w-100 rounded mt-1" @click="downloadLabel()" v-if="shipperDetail.label_check_sum != null"><i class="fa fa-print"></i> Print Label</button>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body pb-0">
                        <button class="btn btn-primary mb-1 w-100 rounded" v-if="model.statusDelivery == 0" @click="updateStatus(1)">Terima Pesanan</button>
                        <button class="btn btn-primary mb-1 w-100 rounded" v-if="model.statusDelivery == 1" @click="updateStatus(2)">Pesanan Siap Kirim</button>
                        <!-- <button class="btn btn-primary mb-1 w-100 rounded" v-if="model.statusDelivery == 2" @click="updateStatus(3)">Kirim Pesanan</button> -->
                        <button class="btn btn-success mb-1 w-100 rounded" @click="downloadPdf()">Lihat Invoice PDF</button>
                        <!-- <button class="btn mb-1 w-100 rounded" :class="model.statusDelivery < 3 ? ' btn-secondary ' : ' btn-success '">Cek Pengiriman Barang</button> -->
                        <button class="btn btn-outline-danger mb-1 w-100 rounded" v-if="model.statusDelivery == 0" @click="updateStatus(5)">Tolak Pesanan</button>
                    </div>
                </div>
                <div class="card" v-if="trackings.length > 0">
                    <div class="card-body">
                        <p class="f-16 font-600">Informasi Pengiriman</p>
                        <div class="row mb-1" v-for="(tracking, index) in trackings" :key="index">
                            <div class="col-6 f-14 text-secondary" style="border-right: 1px solid #ccc;">
                                <p class="f-14 text-primary mb-0 font-600">{{tracking.logistic_status.name}}</p>
                                <p class="f-12 text-secondary font-300 mb-0">{{ tracking.created_date | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                            </div>
                            <div class="col-6 f-14 font-500 pr-1">
                                <p class="f-12 text-secondary font-400 mb-0">
                                    {{ tracking.logistic_status.description}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                statusDeliveryDetail: {},
                paymentChannel: {},
                order_shipping_address: {
                    shipperReference: {
                        rate: {}
                    }
                },
                diagnosis: null
            },
            isEditResi: false,
            trackings: [],
            date: "",
            shipperDetail: {
                label_check_sum: null
            }
        }
    },
    beforeDestroy() {
        let _ = this
        _.$root.$emit('addAdditionalInfo', '')
    },
    methods: {
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        disableTime(date) {  
            return date < new Date();  
        },
        get() {
            let _ = this
            _.axios.get('orders/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data

                    _.$root.$emit('addAdditionalInfo', _.model.orderNumber)
                })
        },
        getTrackingDetail() {
            let _ = this
            _.axios.get('orders/' + _.$route.params.id + '/shipping')
                .then(resp => {
                    _.trackings = resp.data.data.data.trackings
                    _.shipperDetail = resp.data.data.data
                })
        },
        textFormat(x) {
            let str = ''
            for (let i = 0; i < x.length; i++) {
                str = str + x[i] + ', '
            }
            let clean = ''
            for (let i = 0; i < str.length - 2; i++) {
                clean = clean + str[i]
            }
            return clean;
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 0:
                    color = 'btn-success'
                    break;
                case 1:
                    color = 'btn-warning'
                    break;
                case 2:
                    color = 'btn-primary'
                    break;
                case 3:
                    color = 'btn-info'
                    break;
                case 4:
                    color = 'btn-outline-primary'
                    break;
                case 5:
                    color = 'btn-danger'
                    break;
                default:
                    break;
            }
            return color;
        },
        updateNotes() {
            let _ = this
            _.axios.put('orders/' + _.$route.params.id + '/notes', { notes: _.model.notes })
                .then(resp => {
                    _.$swal.fire({
                        title: resp.data.message,
                        text: 'Notes Updated',
                        icon: 'success',
                        confirmButtonColor: '#0036A0',
                        confirmButtonText: 'OK'
                    })
                })
        },
        updateStatus( statusCode ) {
            let _ = this
            let data = {}
            
            if ( statusCode == 3 ) {
                data = {
                    status: statusCode,
                    pickupTime: _.date
                }

                this.isEditResi = false
            } else {
                data = {
                    status: statusCode
                }
            }

            _.axios.put('orders/' + _.$route.params.id + '/shipping', data)
                .then(resp => {
                    console.log(resp.data.message)
                    _.get()
                    _.getTrackingDetail()
                })
        },
        downloadPdf(){
            let _ = this
            // _.isLoading = true

            _.axios.get('/orders/' + _.$route.params.id + '/invoice'
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link        = document.createElement('a')
                    link.href       = window.URL.createObjectURL(blob)
                    link.download   = 'ORDER-INVOICE-' + _.model.orderNumber + '.pdf'
                    link.click()
                    // _.isLoading = false
                })
                .catch(err => {
                    console.log(err.response)
                    // _.isLoading = false
                })
        },
        downloadLabel(){
            let _ = this
            // _.isLoading = true

            _.axios.get('/orders/print-label?shipperId=' + _.model.shipperId + '&labelCheckSum=' + _.shipperDetail.label_check_sum
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link        = document.createElement('a')
                    link.href       = window.URL.createObjectURL(blob)
                    link.download   = 'LABEL-CHECKSUM-' + _.shipperDetail.label_check_sum + '.pdf'
                    link.click()
                    // _.isLoading = false
                })
                .catch(err => {
                    console.log(err.response)
                    // _.isLoading = false
                })
        }
    },
    mounted() {
        let _ = this

        _.$moment.locale('id')
        
        _.get()
        _.getTrackingDetail()
    }
}
</script>