<template>
    <div class="mw-100 p-2">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Profil User 
                            <!-- <span class="text-blue pull-right f-14 font-400 pointer" @click="$router.push('/customer-notes/edit/' + $route.params.id)">Ubah Informasi <i class="fa fa-edit"></i></span> -->
                        </p>
                        <div class="row pl-1">
                            <img :src="model.avatar ? model.avatar : '/assets/img/icon/profile.png'" alt="" class="avatar-small">
                            <div class="col align-self-center">
                                <p class="mb-0 f-16 font-500">{{ model.fullName }}</p>
                                <p class="mb-0 text-blue f-12">{{ model.account.email }}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Email ID</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.account.email }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Tanggal Lahir</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.dateOfBirth | moment('DD MMM YYYY') }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">No HP</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.mobileNumber }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Country</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">Indonesia</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Provinsi</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.province }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Kota/Kabupaten</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.city }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Kecamatan</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.suburb }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Alamat Lengkap</div>
                            <div class="col-7 text-right pr-1">{{ model.address }}</div>
                        </div>
                        <hr>
                        <p class="f-16 font-600">Overview Transaksi</p>
                        <div class="row">
                            <div class="col-3">
                                <p class="f-24 font-600 text-blue text-center">{{ overview.consultActive | numFormat }}</p>
                                <p class="text-secondary f-12 text-center mb-0">Konsultasi Aktif</p>
                            </div>
                            <div class="col-3">
                                <p class="f-24 font-600 text-blue text-center">{{ overview.totalConsultastion | numFormat }}</p>
                                <p class="text-secondary f-12 text-center mb-0">Total Konsultasi</p>
                            </div>
                            <div class="col-3">
                                <p class="f-24 font-600 text-blue text-center">{{ overview.totalShopping | numFormat }}</p>
                                <p class="text-secondary f-12 text-center mb-0">Total Belanja</p>
                            </div>
                            <div class="col-3">
                                <p class="f-24 font-600 text-blue text-center">{{ overview.totalTransaction | numFormat }}</p>
                                <p class="text-secondary f-12 text-center mb-0">Total Transaksi</p>
                            </div>
                        </div>
                        <hr>
                        <p class="f-16 font-600">Informasi Tambahan</p>
                        <div class="row">
                            <div class="col-6">
                                <p class="mb-0 f-14 text-secondary">User ID:</p>
                                <p class="f-14 text-black font-500">{{ model.id }}</p>
                            </div>
                            <div class="col-6">
                                <p class="mb-0 f-14 text-secondary">Last Login:</p>
                                <p class="f-14 text-black font-500">{{ model.account.lastLogin | moment('DD MMM YYYY, HH.mm') }} WIB</p>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="col-6">
                                <p class="mb-0 f-14 text-secondary">Email Verify:</p>
                                <p class="mb-0 f-14 font-500" :class="model.account.isVerified != true ? ' text-danger' : 'text-blue'">{{ model.account.isVerified != true ? 'Not Verified' : 'Verified' }}</p>
                            </div> -->
                            <div class="col-12">
                                <p class="mb-0 f-14 text-secondary">Terdaftar pada:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ model.createdAt | moment('DD MMM YYYY, HH.mm') }} WIB</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <div class="card">
                    <div class="card-body p-0">
                        <ul class="nav nav-tabs pl-1 pr-1" id="myTab" role="tablist">
                            <li class="nav-item m-0" style="border-left: 0px;">
                                <a class="nav-link active pl-0 pr-0 mr-3" style="font-size: 16px !important;" id="order-tab" data-toggle="tab" href="#order" role="tab" aria-controls="order" aria-selected="true">ORDER</a>
                            </li>
                            <li class="nav-item m-0" style="border-left: 0px;">
                                <a class="nav-link pl-0 pr-0" style="font-size: 16px !important;" id="notes-tab" data-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">NOTES</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
                                <p class="f-14 text-black font-500 pl-1 mt-1">Order & Invoice List</p>
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th class="font-600 text-black">No Transaksi</th>
                                                <th class="font-600 text-black">Time</th>
                                                <th class="font-600 text-black">Tipe Pembelian</th>
                                                <th class="font-600 text-black">Status</th>
                                                <th class="font-600 text-black">Nominal Transaksi</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="orders.length > 0">
                                            <tr v-for="(order, index) in orders" :key="index">
                                                <td>{{ order.orderNumber }}</td>
                                                <td>{{ order.dateTime }} WIB</td>
                                                <td><b>{{ order.types ? textFormat(order.types.name) : ''}}</b></td>
                                                <td><div :class="'btn btn-sm ' + statusColor(order.statusDetail.code)">{{ order.statusDetail.name }}</div></td>
                                                <td class="text-blue" nowrap>{{ order.grandtotal | currency }}</td>
                                            </tr>
                                            <tr v-if="page < lastPage">
                                                <td colspan="5">
                                                    <p class="mb-0 text-primary f-14 pointer" @click="page = page + 1"> Load more <i class="fa fa-arrow-down"></i></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="5">Tidak ada data</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade pl-1 pr-1" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                                <p class="f-14 text-black font-500 mt-1">Catatan By Admin</p>
                                <div class="" v-for="(data, index) in notes" :key="index">
                                    <div class="p-1 mb-1" style="
                                        background: #7E8CB71A;
                                        border-radius: 16px;
                                        ">
                                        <p class="f-12 mb-0">{{ data.comment }}</p>
                                    </div>
                                    <p class="f-12">Add by {{ data.admin.fullName }} at <span class="text-secondary">{{ data.dateTime }}</span> 
                                    <span class="text-danger font-500 pointer" @click="deleteNote(data.id)"> Hapus Catatan</span> </p>
                                </div>
                                <p class="text-primary text-center f-14 pointer" v-if="page_notes < lastPage_notes" @click="page_notes = page_notes + 1"> Load more <i class="fa fa-arrow-down"></i></p>
                                <div class="form-group">
                                    <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Tinggalkan komentar" v-model="note.comment"></textarea>
                                </div>
                                <button class="btn btn-primary rounded mb-1 pull-right" @click="addNote()">Beri Komentar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <json-viewer :value="orders"></json-viewer> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                account: {}
            },
            orders: [],
            page: 1,
            lastPage: null,
            overview: {},
            notes: [],
            note: {
                userId: this.$route.params.id,
                comment: ''
            },
            page_notes: 1,
            lastPage_notes: null
        }
    },
    beforeDestroy() {
        let _ = this
        _.$root.$emit('addAdditionalInfo', '')
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('users/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data

                    _.$root.$emit('addAdditionalInfo', _.model.fullName)
                })
        },
        getOrders() {
            let _ = this
            _.axios.get('orders/customers?sortBy=createdAt.DESC&userId=' + _.$route.params.id + '&limit=10&page=' + _.page)
                .then(resp => {
                    let data = resp.data.data.rows
                    for (let i = 0; i < data.length; i++) {
                        _.orders.push(data[i])
                    }
                    _.lastPage = resp.data.data.totalPages
                })
        },
        getOverview() {
            let _ = this
            _.axios.get('users/' + _.$route.params.id + '/overview')
                .then(resp => {
                    _.overview = resp.data.data
                })
        },
        getNotes() {
            let _ = this
            _.axios.get('notes?sortBy=createdAt.DESC&userId=' + _.$route.params.id + '&limit=10&page=' + _.page_notes)
                .then(resp => {
                    let data = resp.data.data.rows
                    for (let i = 0; i < data.length; i++) {
                        _.notes.push(data[i])
                    }
                    _.lastPage_notes = resp.data.data.totalPages
                })
        },
        addNote() {
            let _ = this
            _.axios.post('notes', _.note)
                .then(resp => {
                    console.log(resp.data.data)
                    _.notes = []
                    _.getNotes()
                    _.note = {
                        userId: this.$route.params.id,
                        comment: ''
                    }
                })
        },
        deleteNote(x) {
            let _ = this
            _.axios.delete('notes/' + x)
                .then(resp => {
                    console.log(resp.data.data)
                    _.notes = []
                    _.getNotes()
                })
        },
        textFormat(x) {
            let str = ''
            for (let i = 0; i < x.length; i++) {
                str = str + x[i] + ', '
            }
            let clean = ''
            for (let i = 0; i < str.length - 2; i++) {
                clean = clean + str[i]
            }
            return clean;
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 1:
                    color = 'btn-primary'
                    break;
                case 2:
                    color = 'btn-warning'
                    break;
                case 3:
                    color = 'btn-success'
                    break;
                case 4:
                    color = 'btn-danger'
                    break;
                default:
                    break;
            }
            return color;
        }
    },
    mounted() {
        let _ = this
        _.get()
        _.getOrders()
        _.getOverview()
        _.getNotes()
    },
    watch: {
        page: function(){
            this.getOrders()
        },
        page_notes: function(){
            this.getNotes()
        }
    }
}
</script>