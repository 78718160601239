<template>
    <div class="mw-100 p-2">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <p class="f-20 font-500 text-black">Informasi Pasien</p>
                        <p class="f-12 font-400 text-secondary">Konsul ID: <span class="text-primary f-12 font-600">{{ model.consultId }}</span></p>
                        <p class="f-12 font-400 text-secondary">Type: <span class="text-black f-12 font-600">{{ type }}</span></p>
                        <p class="f-12 font-400 text-secondary">Tgl pengaktifan: <span class="text-black f-12 font-600">{{ model.activationDate  | moment('DD MMM YYYY') }}</span></p>
                        <p class="f-12 font-400 text-secondary">Tgl berakhir: 
                            <span class="text-black f-12 font-600">{{ model.expiredAt | moment('DD MMM YYYY')}}</span>
                            <span class="text-warning f-12 font-600">{{ getDiff(model.expiredAt) > 0 ? ' (Tersisa '+getDiff(model.expiredAt)+' Hari)' : '' }}</span>
                        </p>
                        <p class="font-500 f-14 mb-0 text-black"><b>{{ model.user.fullName }}</b> <span class="f-12 font-400">{{ model.user.gender }},  {{ getAge(model.user.dateOfBirth) }}</span></p>
                        <p class="f-12 font-400 text-primary">{{ model.user.account.email }}</p>
                        <p class="font-400 f-12 text-secondary">File Teleconsult 
                            <span @click="downloadConsult(model.questionnaireReport)" class="pointer text-blue pull-right font-600 f-14"><i class="fa fa-link"></i> Download</span>
                        </p>
                        <p class="font-400 f-12 text-secondary" v-viewer>
                            Media
                            <img 
                                v-for="(media, index) in model.media" 
                                :key="index" 
                                :src="media" 
                                alt="" 
                                width="36"
                                height="36"
                                class="rounded pointer pull-right"
                                style="object-fit: cover; margin-left: 4px;"
                            >
                        </p>
                        <p class="font-400 f-12 text-secondary mb-0">Dokter <span class="text-black font-600 pull-right">{{ model.doctor ? model.doctor.fullName : '-'}}</span></p> 
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <div class="card" v-for="(data, index) in diagnoses" :key="index">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="f-20 font-500 text-black">Rekam Medis</span>
                            <span class="btn btn-primary rounded">{{ data.createdAt | moment('DD MMM YYYY')}}</span>
                        </div>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">P&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 font-400 mb-0">{{ data.p }}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">D&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 font-400">{{ data.diagnosisDetail.diagnosis }}</p>
                                <p class="f-14 font-400 mb-0">{{ data.dNotes }}</p>
                            </div>
                        </div>
                        <hr>
                        <div v-if="data.userProductChoice && data.userProductChoice.length > 0">
                            <div class="row" v-for="(userProduct, index) in data.userProductChoice" :key="index">
                                <div class="ml-1 f-20 font-600 text-primary">R/</div>
                                <div class="col align-self-center">
                                    <p class="f-16 font-500 text-warning">Produk Reference by user </p>
                                    <p class="f-14 text-black font-500 mb-0">{{ userProduct.name }} 
                                        <!-- <small>(Qty: {{ userProduct.consult_product.qty }})</small> -->
                                    </p>
                                    <p class="f-12 font-300 mb-0">{{ userProduct.description }}</p>
                                    <!-- <a href="#" class="text-primary f-12 font-600">Lihat Detail Produk</a> -->
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div v-if="data.recipes.length > 0">
                            <div class="row">
                                <div class="ml-1 f-20 font-600 text-primary">R/</div>
                                <div class="col align-self-center">
                                    <div class="" v-for="(recipe, index) in data.recipes" :key="index">
                                        <p class="f-14 text-black font-500 mb-0">
                                            {{recipe.product.title}}
                                            <span class="f-12 font-400 text-secondary"> - QTY: {{ recipe.usageAmount }} - {{ recipe.usageInterval }}</span>
                                            <span v-if="recipe.product.deletedAt" class="medicine-label bg-danger">Discontinued</span>
                                            <span v-if="recipe.isPurchased" class="medicine-label bg-success">Paid</span>
                                        </p>
                                        <p>
                                            <span class="f-12 font-600" :class="recipe.product.discountPrice > 0 ? 'strikethrough' : 'text-warning'">{{ recipe.product.price | currency }}</span>
                                            <span class="ml-1 text-warning f-12 font-600" v-if="recipe.product.discountPrice > 0">{{ recipe.product.discountPrice | currency }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr class="mt-0">
                        </div>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">C&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 mb-0" v-html="data.c"></p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">K&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 mb-0 text-primary font-500">{{ data.k | moment('DD MMM YYYY')}}</p>
                            </div>
                        </div>
                        <hr class="mb-0">
                    </div>
                </div>
            </div>
        </div>

        <!-- <json-viewer :value="orders"></json-viewer> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                user: {
                    account: {}
                }
            },
            diagnoses: [],
            type: ''
        }
    },
    methods: {
        getDiff(x){
            return this.$moment(x).diff(this.$moment(), 'days')
        },
        getDetail() {
            let _ = this
            _.axios.get('consults/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data


                    if ( _.model.orders && _.model.orders.length > 0 ) {
                        _.model.orders.forEach(order => {
                            order.types.name.forEach(type => {
                                _.type = _.type + ', ' + type
                            })
                        });
                        _.type = _.type.substring(2, _.type.length)
                    }
                })
        },
        getDiagnoses() {
            let _ = this
            _.axios.get('consults/' + _.$route.params.id + '/diagnoses')
                .then(resp => {
                    _.diagnoses = resp.data.data.rows
                })
        },
        getAge(birthday) {
            if(birthday){
                var totalMonths = this.$moment().diff(birthday, 'months');
                var years = parseInt(totalMonths / 12);
                var months = totalMonths % 12;

                if( months !== 0 ){
                    return years + ' tahun ' + months + ' bulan';
                }
                return years + ' tahun';
            }
            return null;
        },
        downloadConsult(url) {
            fetch(url, { headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken')}})
                .then(res => res.blob())
                .then(blob => {
                    let link        = document.createElement('a')
                    link.href       = window.URL.createObjectURL(blob)
                    link.download   = this.model.consultId + '.pdf'
                    link.click()
                })
        }
    },
    mounted() {
        let _ = this
        _.getDetail()
        _.getDiagnoses()
    }
}
</script>

<style>
.medicine-label {
    color: white;
    padding: 2px 4px;
    font-size: .75rem;
    margin-left: 8px;
    border-radius: 5px;
}
</style>