<template>
    <div class="section mw-100 p-2">
        <div class="card">
            <div class="card-body p-0">
                <div class="row pl-2 pt-2 pr-2 pb-1" style="gap: 1rem">
                    <p class="align-self-center f-16 font-600 text-black ml-2 mb-0 mr-1">Status</p>
                    <button type="button" class="btn btn-sm" :class="activeButton === '' ? 'btn-primary' : 'btn-outline-secondary'"  @click="activeButton = ''">Semua</button>
                    <button type="button" class="btn btn-sm" :class="activeButton === 0 ? 'btn-primary' : 'btn-outline-secondary'"  @click="activeButton = 0">Pesanan Baru</button>
                    <button type="button" class="btn btn-sm" :class="activeButton == 1 ? 'btn-primary' : 'btn-outline-secondary'"  @click="activeButton = 1">Pesanan Diterima</button>
                    <button type="button" class="btn btn-sm" :class="activeButton == 2 ? 'btn-primary' : 'btn-outline-secondary'"  @click="activeButton = 2">Pesanan Siap Kirim</button>
                    <button type="button" class="btn btn-sm" :class="activeButton == 3 ? 'btn-primary' : 'btn-outline-secondary'"  @click="activeButton = 3">Sedang Dikirim</button>
                    <button type="button" class="btn btn-sm" :class="activeButton == 4 ? 'btn-primary' : 'btn-outline-secondary'"  @click="activeButton = 4">Selesai</button>
                    <p class="align-self-center f-16 font-600 text-blue ml-1 mb-0 pointer" 
                        @click="activeButton = '', bulk_action = '', search = '', date = null">
                        Reset Filter
                    </p>
                </div>
                <div class="row pl-2 pr-2 pb-1 col-lg-10" style="gap: 1rem">
                    <!-- <div class="col">
                        <select name="" id="" class="form-control" @change="bulkAction($event)" v-model="bulk_action">
                            <option value="">Bulk Action</option>
                            <option value="1">Terima Pesanan</option>
                            <option value="2">Pesanan Siap Kirim</option>
                        </select>
                    </div> -->
                    <div class="input-group col-12 col-md">
                        <span class="input-group-prepend">
                            <div class="input-group-text border-right-0 bg-transparent text-blue">
                                <i class="fa fa-search"></i>
                            </div>
                        </span>
                        <input class="form-control border-left-0" v-model="search" type="search" placeholder="Cari transaksi...">
                    </div>
                    <p class="align-self-center mb-0 f-14 font-600">Tanggal</p>
                    <div class="input-group col">
                        <div class="position-relative">
                            <date-picker
                                v-model="date"
                                format="DD/MM/YYYY"
                                type="date"
                                input-class="form-control"
                                value-type="YYYY-MM-DD"
                                placeholder="Pilih tanggal"
                                style="width: 100%;">
                            </date-picker>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th class="font-600 text-black"><input type="checkbox" @click="checkAll($event)"></th>
                                <th class="font-600 text-black">No Transaksi</th>
                                <th class="font-600 text-black">Tgl Order</th>
                                <th class="font-600 text-black">Status</th>
                                <th class="font-600 text-black">Tipe</th>
                                <th class="font-600 text-black">Alamat</th>
                                <th class="font-600 text-black">Kurir</th>
                                <th class="font-600 text-black">Pesanan</th>
                                <th class="font-600 text-black" style="width: 10px;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in table.dataTable.lists.rows" :key="index">
                                <td><input type="checkbox" @click="checkRow(data.id, $event)" :checked="checkedRow.some((obj)=> obj == data.id)"></td>
                                <td>
                                    <router-link :to="`/apotek-dan-pengiriman/detail/${data.id}`" style="text-decoration: underline">
                                        {{ data.orderNumber }}
                                    </router-link>
                                </td>
                                <td style="white-space: pre;">{{ data.createdAt | moment('LL[\n]HH:mm') }}</td>
                                <td><div :class="'btn btn-sm ' + statusColor(data.statusDeliveryDetail.code)">{{ data.statusDeliveryDetail.name }}</div></td>
                                <td style="white-space: pre;">{{ data.types ? textFormat(data.types.name) : ''}}</td>
                                <td>
                                    <b>{{ data.order_shipping_address ? data.order_shipping_address.name : ''}}</b><br>
                                    {{ data.order_shipping_address ? data.order_shipping_address.address : ''}}
                                </td>
                                <td>
                                    <b v-if="data.order_shipping_address && data.order_shipping_address.shipperReference && data.order_shipping_address.shipperReference.logistic">
                                        {{ data.order_shipping_address.shipperReference.logistic.name }}
                                    </b>
                                    <b v-else>-</b>
                                    <span v-if="data.order_shipping_address && data.order_shipping_address.shipperReference && data.order_shipping_address.shipperReference.rate">
                                        {{ `(${data.order_shipping_address.shipperReference.rate.name})` }}
                                    </span>
                                    <p v-if="data.shipperId" class="f-12 mb-0">
                                        Resi Shipper: {{ data.shipperId }}
                                    </p>
                                    <p v-if="data.awb" class="f-12 mb-0">
                                        Resi Operator: {{ data.awb }}
                                    </p>
                                </td>
                                <td>
                                    <ul v-if="data.productReference && data.productReference.length > 0" class="pl-1">
                                        <li v-for="(produk, index_produk) in data.productReference" :key="index_produk">
                                            {{ produk.name ? produk.name : produk }}
                                        </li>
                                    </ul>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-primary rounded" v-if="data.statusDelivery == 0" @click="updateStatus(data.id, 1)">
                                        Terima
                                    </button>
                                    <button type="button" class="btn btn-sm btn-success rounded" v-if="data.statusDelivery == 1" @click="updateStatus(data.id, 2)">
                                        Pesanan Siap Kirim
                                    </button>
                                    <router-link :to="'/apotek-dan-pengiriman/detail/' + data.id">
                                        <button type="button" class="btn btn-sm btn-success rounded" v-if="data.statusDelivery == 2">
                                            Kirim Barang
                                        </button>
                                    </router-link>
                                    <router-link :to="'/apotek-dan-pengiriman/detail/' + data.id">
                                        <button type="button" class="btn btn-sm btn-outline-primary rounded" v-if="data.statusDelivery > 2">
                                            Cek Transaksi
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col text-center mt-1">
                    <p class="f-14" v-if="table.dataTable.lists.count == 0 || !table.dataTable.lists.rows">There is no data</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body pl-3">
                <PaginateList :data="table.dataTable.lists" v-if="table.dataTable.lists.total != 0"/>
            </div>
        </div>

        <!-- <json-viewer :value="checkedRow"></json-viewer> -->
    </div>
</template>

<script>
import PaginateList from '@/components/PaginateList'
// import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
	components: {
        // Table,
        PaginateList
	},
	data() {
		return {
			table: {
                dataTable: {
                    lists: [],
                }
			},
            filter: '',
            search: '',
            limit: 10,
            isLoading: false,
            activeButton: 0,
            date: null,
            checkedRow: [],
            bulk_action: ''
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$moment.locale('id')
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/orders/shippings?sortBy=createdAt.DESC&page=' + (page ? page : 1) + '&limit=' + _.limit
                    + ( _.activeButton !== '' ? '&statusDelivery=' + _.activeButton : '')
                    + '&search=' + _.search
                    + ( _.date != null ? '&createdAt=betweenDate:' + _.date + ',' + _.date : '')
                )
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete Flow?',
                text: x.fullName,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/products/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        textFormat(x) {
            let str = ''
            for (let i = 0; i < x.length; i++) {
                str = str + x[i] + ',\n'
            }
            let clean = ''
            for (let i = 0; i < str.length - 2; i++) {
                clean = clean + str[i]
            }
            return clean;
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 0:
                    color = 'btn-success'
                    break;
                case 1:
                    color = 'btn-warning'
                    break;
                case 2:
                    color = 'btn-primary'
                    break;
                case 3:
                    color = 'btn-info'
                    break;
                case 4:
                    color = 'btn-outline-primary'
                    break;
                case 5:
                    color = 'btn-danger'
                    break;
                default:
                    break;
            }
            return color;
        },
        checkRow(value, event){
            let _ = this
            if (event.target.checked) {
                _.checkedRow.push(value)                
            } else {
                const index = _.checkedRow.indexOf(value);
                if (index > -1) {
                    _.checkedRow.splice(index, 1);
                }
            }
        },
        checkAll(event){
            let _ = this
            let data = _.table.dataTable.lists.rows
            if (event.target.checked) {
                for (let i = 0; i < data.length; i++) {
                    _.checkedRow.push(data[i].id)       
                }                
            } else {
                _.checkedRow = []
            }
        },
        updateStatus( id, statusCode ) {
            let _ = this
            let data = {
                status: statusCode
            }

            _.axios.put('orders/' + id + '/shipping', data)
                .then(resp => {
                    console.log(resp.data.message)
                    _.getList()
                })
        },
        bulkAction( event ) {
            let _ = this
            let data = {
                status: event.target.value,
                orderIds: _.checkedRow
            }

            _.axios.put('orders/shipping', data)
                .then(resp => {
                    console.log(resp.data.message)
                    _.bulk_action = ''
                    _.checkedRow = []
                    _.getList()
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        date: function(){
            this.getList()
        },
        activeButton: function() {
            this.getList()
        }
    }
}
</script>