<template>
  <div v-show="isActive" style="flex: 1; overflow: auto;">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: ['title'],
    data () {
      return {
        isActive: true
      }
    }
  }
</script>