<template>
  <div class="d-flex flex-column align-items-stretch h-100">
    <ul class="d-flex flex-row align-items-stretch p-0" style="list-style-type: none; border-bottom: 1px solid #BCC0D1;">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        :class="{
          'd-flex flex-row align-items-center justify-content-center py-1 px-0 cursor-pointer position-relative text-center': true,
          'active-tab': index === selectedIndex,
        }"
        style="flex: 1;"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        selectedIndex: 0,
        tabs: []
      }
    },
    created () {
      this.tabs = this.$children
    },
    mounted () {
      this.selectTab(0)
    },
    methods: {
      selectTab (i) {
        this.selectedIndex = i

        this.tabs.forEach((tab, index) => {
          tab.isActive = (index === i)
        })
      }
    }
  }
</script>

<style>
.active-tab {
  color: #0B54F7;
}
.active-tab:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  background-color: #0B54F7;
}
</style>