export default {
	login({ commit }, payload) {
		commit('UPDATE_USER', payload)
	},
	tryAutoLogin({ commit }) {
		let updateUser = {
			accessToken: localStorage.getItem('accessToken'),	
			account: JSON.parse(localStorage.getItem('account'))				
		}				
		commit('UPDATE_USER', updateUser)
	},
	logout({ commit }) {
		commit('LOGOUT_USER')
	}
}