<template>
    <div class="section mw-100 p-2">
        <div class="card">
            <div class="card-body p-0">
                <div class="row pl-2 pt-2 pr-2 pb-1" style="gap: 1rem">
                    <p class="align-self-center f-16 font-600 text-black ml-2 mb-0">Status</p>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': activeButton == 'semua', 'btn-outline-secondary': activeButton != 'semua'}" @click="setActive('semua')">Semua</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': activeButton == 'baru', 'btn-outline-secondary': activeButton != 'baru'}"  @click="setActive('baru')">Pesanan Baru</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': activeButton == 'onproses', 'btn-outline-secondary': activeButton != 'onproses'}"  @click="setActive('onproses')">On Proses</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': activeButton == 'selesai', 'btn-outline-secondary': activeButton != 'selesai'}"  @click="setActive('selesai')">Selesai</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': activeButton == 'dibatalkan', 'btn-outline-secondary': activeButton != 
                    'dibatalkan'}"  @click="setActive('dibatalkan')">Dibatalkan</button>
                    
                    <p class="align-self-center f-16 font-600 text-blue ml-1 mb-0 pointer"
                        @click="activeButton = 'semua', search = '', date = '', setActive('semua')">
                        Reset Filter
                    </p>
                </div>

                <div class="row pl-2 pr-2 pb-1 col-lg-12" style="gap: 1rem 0">
                    <div class="input-group col-lg-3">
                        <span class="input-group-prepend">
                            <div class="input-group-text border-right-0 bg-transparent text-blue">
                                <i class="fa fa-search"></i>
                            </div>
                        </span>
                        <input class="form-control border-left-0" v-model="search" type="search"
                            placeholder="Cari transaksi...">
                    </div>
                    <div class="col-lg-3 d-flex align-items-center" style="gap: 1rem">
                        <p class="align-self-center mb-0 f-14 font-600">Tanggal</p>
                        <date-picker
                            v-model="date"
                            format="DD/MM/YYYY"
                            type="date"
                            input-class="form-control"
                            value-type="YYYY-MM-DD"
                            placeholder="Pilih tanggal"
                            style="width: 100%">
                        </date-picker>
                    </div>
                    <div class="col-lg-3 d-flex align-items-center" style="gap: 1rem">
                        <p class="align-self-center mb-0 f-14 font-600">Tipe</p>
                        <multiselect 
                            v-model="type"
                            placeholder="Tipe Transaksi"
                            :options="[
                                { value: 1, label: 'Konsultasi' },
                                { value: 2, label: 'Belanja' },
                                { value: 3, label: 'Konsultasi, Belanja' }
                            ]"
                            label="label"
                            track-by="value"
                            select-label=""
                            deselect-label="Reset"
                            :multiple="false"
                            :searchable="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3 d-flex align-items-center" style="gap: 1rem">
                        <p class="align-self-center mb-0 f-14 font-600">Poliklinik</p>
                        <multiselect 
                            v-model="polyclinic"
                            placeholder="Poliklinik"
                            :options="polyclinicOptions"
                            select-label=""
                            deselect-label="Reset"
                            :multiple="false"
                            :searchable="false"
                            :loading="polyclinicOptions.length === 0"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                            <th scope="col" class="font-600 text-black">No Transaksi</th>
                            <th scope="col" class="font-600 text-black">Tgl Transaksi</th>
                            <th scope="col" class="font-600 text-black">Status Transaksi</th>
                            <th scope="col" class="font-600 text-black">Tipe</th>
                            <th scope="col" class="font-600 text-black">Nama</th>
                            <th scope="col" class="font-600 text-black">Poliklinik</th>
                            <th scope="col" class="font-600 text-black">Pesanan</th>
                            <th scope="col" class="font-600 text-black">Kurir</th>
                            <th scope="col" class="font-600 text-black">Metode Pembayaran</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, key) in table.dataTable.lists.rows" :key="key">
                                <td>
                                    <router-link :to="`/daftar-transaksi/${order.id}`" style="text-decoration: underline">
                                        {{ order.orderNumber }}
                                    </router-link>
                                </td>
                                <td style="white-space: pre;">{{ order.createdAt | moment('LL[\n]HH:mm')  }}</td>
                                <td><div class="btn btn-sm" :class="{ 'btn-success': order.statusTransaction == 0, 'btn-warning': order.statusTransaction == 1, 'btn-primary': order.statusTransaction == 2, 'btn-danger': order.statusTransaction == 3 }" >
                                    {{ order.statusTransactionDetail ?  order.statusTransactionDetail.name : '-'}}
                                </div></td>
                                <td style="white-space: pre;">{{ getType(order.types) }}</td>
                                <td>{{ order.user.fullName }}</td>
                                <td>{{ order.subClinic }}</td>
                                <td><ul class="pl-1"><li v-for="(pr, k)  in order.productReference" :key="k">{{ pr.name ? pr.name : pr }}</li></ul></td>
                                <td>
                                    <b v-if="order.order_shipping_address && order.order_shipping_address.shipperReference && order.order_shipping_address.shipperReference.logistic">
                                        {{ order.order_shipping_address.shipperReference.logistic.name }}
                                    </b>
                                    <b v-else>-</b>
                                    <span v-if="order.order_shipping_address && order.order_shipping_address.shipperReference && order.order_shipping_address.shipperReference.rate">
                                        {{ `(${order.order_shipping_address.shipperReference.rate.name})` }}
                                    </span>
                                    <p v-if="order.shipperId" class="f-12 mb-0">
                                        Resi Shipper: {{ order.shipperId }}
                                    </p>
                                    <p v-if="order.awb" class="f-12 mb-0">
                                        Resi Operator: {{ order.awb }}
                                    </p>
                                </td>
                                <td>{{ order.paymentChannel ? order.paymentChannel.name : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body pl-3">
                <PaginateList :data="table.dataTable.lists" v-if="table.dataTable.lists.total != 0"/>
            </div>
        </div>
    </div>
</template>

<script>
import PaginateList from '@/components/PaginateList'
import { debounce } from "debounce";

export default {
    components: {
        // Table,
        PaginateList
    },
    data() {
        return {
            orders: [],
            activeButton: 'semua',
            table: {
                dataTable: {
                    header: ['User', 'Email', 'Phone', 'Terakhir Order', 'Status', 'Action'],
                    columns: ['user', 'email', 'phone', 'last_order', 'status'],
                    lists: [],
                }
            },
            filter: '',
            search: '',
            limit: 10,
            isLoading: false,
            status: null,
            date: '',
            type: null,
            polyclinic: '',
            polyclinicOptions: [],
        }
    },
    mounted() {
        let _ = this
        _.$moment.locale('id')
        _.getPolyclinic();
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });

        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    methods: {
        getList(page) {
            let _ = this
            let sts = _.status != null ? '&statusTransaction=in:' + _.status : ''
            let src = _.search != "" ? '&search=' + _.search : ''
            let dt = _.date != "" ? '&createdAt=betweenDate:' + _.date + ',' + _.date : ''
            const typeParam = _.type && _.type.value ? `&type=${_.type.value}` : '';
            const polyclinicParam = _.polyclinic ? `&subClinic=${_.polyclinic.split(' ').join('+')}` : '';
            
            _.axios.get('/orders/transactions?sortBy=createdAt.desc&page=' + (page ? page : 1) + '&limit=' + _.limit + sts + src + dt + typeParam + polyclinicParam)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getPolyclinic() {
            this.axios.get('/dc-poly')
                .then(res => {
                    if (res.data.data && res.data.data.rows) {
                        this.polyclinicOptions = res.data.data.rows.map(item => item.namePage);
                    }
                })
                .catch(err => {
                    console.log(err.response);
                });
        },
        getType(types) {
            if ( types ) {
                return types.name.join(',\n')
            } else {
                return '-'
            }
        },
        setActive(s) {
            let _ = this
            this.activeButton = s

            if ( s == 'baru' ) {
                _.status = 0
            } else if ( s == 'onproses' ) {
                _.status = 1
            } else if ( s == 'selesai' ) {
                _.status = 2
            } else if ( s == 'dibatalkan' ) {
                _.status = 3
            } else {
                _.status = null
            }

            _.getList()
        }
    },
    watch: {
        'search': function() {
            let _ = this
            _.getList()
        },
        'date': function(newValue) {
            let _ = this
            
            if ( newValue == null ) {
                _.date = ""
            }

            _.getList()
        },
        type: function() {
            this.getList();
        },
        polyclinic: function() {
            this.getList();
        },
    }
}
</script>
