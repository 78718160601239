<template>
    <div :class="{'d-flex flex-row wrapper': true, 'wrapper__hidden-navbar': !isNavbarShow}">
        <div :class="{'d-none': consult,'d-md-block bg-white h-100 overflow-auto': true}" style="flex: 1; border-right: 1px solid #BCC0D1;">
            <div class="input-group pl-1 pr-1 mt-1 mb-1">
                <span class="input-group-prepend">
                    <div class="input-group-text">
                        <a class="text-blue">
                            <i class='fa fa-search'></i>
                        </a>
                    </div>
                </span>
                <input type="text" class="form-control" v-model="search_message" placeholder="Cari konsul, contoh: KCA94427l...">
            </div>
            <div class="mb-1 pl-1 pr-1" style="
                    display:flex;
                    overflow: auto;
                    flex-wrap: unset;
                ">
                <button :class="{'btn btn-sm btn-primary rounded ': filterActive == 'semua', 'btn btn-sm btn-outline-secondary rounded': filterActive != 'semua'}" @click="filterActive = 'semua'" style="margin-right: 5px;white-space: nowrap;">Semua</button>
                <button :class="{'btn btn-sm btn-primary rounded ': filterActive == 'aktif', 'btn btn-sm btn-outline-secondary rounded': filterActive != 'aktif'}" @click="filterActive = 'aktif'" style="margin-right: 5px;white-space: nowrap;">Aktif</button>
                <button :class="{'btn btn-sm btn-primary rounded ': filterActive == 'review', 'btn btn-sm btn-outline-secondary rounded': filterActive != 'review'}" @click="filterActive = 'review'" style="margin-right: 5px;white-space: nowrap;">Review</button>
                <button :class="{'btn btn-sm btn-primary rounded ': filterActive == 'review-to', 'btn btn-sm btn-outline-secondary rounded': filterActive != 'review-to'}" @click="filterActive = 'review-to'" style="margin-right: 5px;white-space: nowrap;">Review TO</button>
                <button :class="{'btn btn-sm btn-primary rounded ': filterActive == 'berakhir', 'btn btn-sm btn-outline-secondary rounded': filterActive != 'berakhir'}" @click="filterActive = 'berakhir'" style="margin-right: 5px;white-space: nowrap;">Berakhir</button>
            </div>
            <div style="overflow: auto;" id="chat-list">
                <div class="pl-1 pr-1 pt-1 pointer chat " :class="activeChat == data.id ? 'active' : ''" v-for="(data, index) in consults" :key="index"
                    @click="subscribe(data)">
                    <div style="border-bottom: 0.5px solid #BCC0D1;" class="pb-1" v-if="data.consult">
                        <p class="font-600 f-14">{{ data.consult.user.fullName }} 
                            <span class="pull-right f-12 font-400">{{data.updatedAt | moment('D MMM Y, HH:mm')}}</span>
                        </p>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-sm btn-outline-primary rounded mb-1" style="background: inherit;">{{ data.consult.subClinic }}</button>&nbsp;
                                <button class="btn btn-sm rounded mb-1 " :class="consultColor(data.status, data.consult.activationDate)">{{ consultStatus(data.status, data.consult.activationDate) }}</button>
                                <p class="f-12 font-400 mb-0" v-if="data.lastMessage">
                                    <span v-if="data.lastMessage.attachment">
                                        <img :src="image" alt="" v-for="(image, idx_image) in data.lastMessage.attachment" :key="idx_image" style="width: 50px; height: 50px;" class="rounded">
                                    </span>
                                    <span v-if="data.lastMessage.message" style="overflow-wrap: break-word;">
                                        {{ data.lastMessage.message.length > 100 ? html_striptag(data.lastMessage.message).substring(0, 100) + '...' : html_striptag(data.lastMessage.message) }}
                                    </span> 
                                </p>
                            </div>
                            <div class="col-2" v-if="data.is_new == true">
                                <span class="badge badge-primary">New</span>
                            </div>
                            <div class="col-2" v-else-if="data.unreadAdminCount > 0">
                                <span class="badge badge-danger">{{data.unreadAdminCount}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="text-primary text-center f-12 mt-2 mb-3 pointer" @click="page += 1" v-if="page < lastPage">
                    Muat lebih banyak <span class="fa fa-arrow-down"></span>
                </p>
            </div>
        </div>
        <div v-if="!consult" class="d-none d-md-flex flex-column bg-white h-100 align-items-center justify-content-center" style="flex: 2; gap: 1rem;">
            <img src="/assets/img/consult-illustration.png" class="" alt="" />
            <p class="text-dark">Pilih konsultasi untuk membuka chat dan detail konsultasi.</p>
        </div>
        <div v-if="consult" class="bg-white h-100" style="flex: 2;">
            <div class="d-flex flex-column h-100">
                <div class="p-1 d-flex justify-content-between align-items-center" style="border-bottom: 1px solid #BCC0D1">
                    <div class="d-flex justify-content-start" style="gap: .75rem">
                        <img src="/assets/img/icon/arrow-left-icon.svg" class="cursor-pointer d-block d-md-none" width="26" height="26" @click="closeChatRoom" alt="" />
                        <div>
                            <p class="text-dark" style="margin-bottom: .5rem">{{ consult.user.fullName }}</p>
                            <div class="d-flex flex-wrap" style="gap: .5rem">
                                <span class="btn btn-sm btn-outline-primary rounded">{{ consult.subClinic }}</span>
                                <span class="btn btn-sm rounded" :class="consultColor(consult.consultationStatus, consult.activationDate)">{{ consultStatus(consult.consultationStatus, consult.activationDate) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="gap: 1rem">
                        <img src="/assets/img/icon/info-circle-icon.svg" class="cursor-pointer" width="24" @click="openInfo" alt="" />
                        <img src="/assets/img/icon/prescription-icon.svg" class="cursor-pointer" width="24" @click="openPrescription" alt="" />
                    </div>
                </div>
                <div class="overflow-auto" style="flex: 1; max-width: 100vw;" id="chat-window">
                    <div class="chat-content p-1 d-flex flex-column justify-content-end" style="min-height: 100%" v-if="messages.length > 0">
                        <div v-for="(message, index) in messages" :key="index">
                            <div v-if="message.accountId != consult.user.accountId">
                                <div class="chat-item right">
                                    <div class="chat-even" v-if="message.attachment && message.attachment.length > 0" v-viewer>
                                        <img :src="image" alt="" v-for="(image, idx_image) in message.attachment" :key="idx_image" 
                                            style="width: 64px; height: 64px;" class="rounded ml-1 pointer"><br>
                                        <span v-html="message.message"></span>
                                    </div>
                                    <div class="chat-even" style="overflow-wrap: break-word;" v-else-if="message.message != ''" v-html="message.message" />
                                </div>
                                <p class="text-right f-12" style="margin-top:5px;">
                                    <b>{{ message.name }} - <span class="text-blue">{{ message.role }}</span></b><br>
                                    <span style="color:#8C93A0">{{ message.timestamp | moment('DD MMM YYYY - HH:mm')}} WIB</span>
                                </p>
                            </div>
                            <div v-else>
                                <div class="chat-item left">
                                    <div class="chat-odd" v-if="message.attachment && message.attachment.length > 0" v-viewer>
                                        <img :src="image" alt="" v-for="(image, idx_image) in message.attachment" :key="idx_image" 
                                            style="width: 64px; height: 64px;" class="rounded mr-1 pointer"><br>
                                        <span v-html="message.message"></span>
                                    </div>
                                    <div class="chat-odd" style="overflow-wrap: break-word;" v-else-if="message.message != ''" v-html="message.message" />
                                </div>
                                <p class="f-12" style="margin-top:5px;">
                                    <span style="color:#8C93A0">{{ message.timestamp | moment('DD MMM YYYY - HH:mm')}} WIB</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row bg-white w-100" v-if="is_closed(consult) == false">
                    <div class="p-1 w-100" style="border-top: 1px solid #BCC0D1;">
                        <div class="row">
                            <div v-for="(image, index) in uploadedImages" :key="index">
                                <div v-if="image != null" class="text-center profile-avatar">
                                    <img :src="image" class="img-fluid rounded m-1" alt="" style="width: 50px; height: 50px;"><br>
                                    <div class="edit-avatar">
                                        <button class="btn btn-rounded btn-danger" 
                                            title="Delete Image" @click="deleteFile(index)"
                                            type="button">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>                                                        
                            </div>
                        </div>

                        <div class="d-flex flex-row align-items-stretch" style="gap: .75rem;">
                            <div style="flex: 1;">
                                <quill-editor 
                                    v-model="consult.form.message" 
                                    v-on:keyup.enter="sendMessage()"
                                    :options="editorOption"
                                    ref="inputChat"
                                />
                            </div>
                            <emoji-picker @emoji="append" :search="search" class="position-relative d-flex align-items-center">
                                <div
                                    class="emoji-invoker"
                                    slot="emoji-invoker"
                                    slot-scope="{ events: { click: clickEvent } }"
                                    @click.stop="clickEvent">
                                    <img src="/assets/img/icon/emoji-icon.svg" class="cursor-pointer" width="21" height="21" alt="" />
                                </div>

                                <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                    <div class="emoji-picker" style="top: 0; right: 0; transform: translateY(-100%);">
                                    <div class="emoji-picker__search">
                                        <input type="text" v-model="search" v-focus>
                                    </div>
                                    <div>
                                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                                            <h5>{{ category }}</h5>
                                            <div class="emojis">
                                                <span
                                                    v-for="(emoji, emojiName) in emojiGroup"
                                                    :key="emojiName"
                                                    @click="insert(emoji)"
                                                    :title="emojiName">
                                                    {{ emoji }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </emoji-picker>
                            <div class="border-left-secondary border-right-secondary d-flex align-items-center" style="margin: 5px 0; padding: 0 .75rem;">
                                <label for="file-upload" class="cursor-pointer">
                                    <img src="/assets/img/icon/pick-image-icon.svg" width="21" height="21" alt="" />
                                </label>
                                <input id="file-upload" class="input-file-avatar d-none" type="file" @change="handleFileUpload( $event )">
                            </div>
                            <div class="d-flex align-items-center">
                                <img src="/assets/img/icon/send-icon.svg" @click="sendMessage()" class="cursor-pointer" width="21" height="21" alt="" />
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
        </div>

        <!-- Info -->
        <portal to="portal1">
            <div
                class="position-fixed"
                :style="`top: 0; left: ${isInfoOpen ? 0 : '100%'}; bottom: 0; right: 0; z-index: 2000; background-color: rgba(39, 42, 51, .3);`"
                v-if="consult"
                @click="closeInfo"
            >
                <div
                    class="position-fixed bg-white"
                    :style="`top: 0; bottom: 0; right: 0; max-width: 75vw; width: ${infoWrapperWidth}; transform: translateX(${isInfoOpen ? 0 : infoWrapperWidth}); transition: transform .2s ease-in;`"
                    @click="(e) => e.stopPropagation()"
                >
                    <div class="h-100 overflow-auto p-1 ">
                        <div class="mb-1">
                            <p class="font-500 f-14 text-dark mb-0"><b>{{ consult.user.fullName }}</b></p>
                            <p class="f-12 font-400 mb-0">{{ consult.user.gender }},  {{ getAge(consult.user.dateOfBirth) }}</p>
                            <p class="font-400 f-12 text-light-blue mb-0">{{ consult.user.account.email }}</p>
                        </div>
                        <div class="mb-1">
                            <p class="f-12 font-400" style="margin-bottom: 4px">File Teleconsult</p>
                            <p
                                class="cursor-pointer text-light-blue font-500 f-14 d-flex flex-row align-items-center"
                                style="gap: .25rem"
                                @click="downloadConsult(consult.questionnaireReport)"
                            >
                                <img src="/assets/img/icon/link-icon.svg" width="18" alt="" />
                                <span>Download</span>
                            </p>
                        </div>
                        <div>
                            <p class="f-12 font-400" style="margin-bottom: 4px">Media</p>
                            <div v-if="consult.media && consult.media.length > 0" class="d-flex flex-row flex-wrap" style="gap: .5rem" v-viewer>
                                <img
                                    v-for="(media, index) in consult.media" 
                                    class="rounded pointer"
                                    :key="index" 
                                    :src="media"
                                    width="64"
                                    height="64"
                                    style="object-fit: cover;"
                                    alt=""
                                />
                            </div>
                            <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
                        </div>
                        <div class="separator" />
                        <div class="mb-1">
                            <p class="f-12 font-400" style="margin-bottom: 4px">Tgl pengaktifan</p>
                            <p v-if="consult.activationDate" class="font-500 f-14 text-dark mb-0"><b>{{ consult.activationDate | moment('DD MMM YYYY') }}</b></p>
                            <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
                        </div>
                        <div>
                            <p class="f-12 font-400" style="margin-bottom: 4px">Tgl berakhir</p>
                            <p v-if="consult.expiredAt" class="font-500 f-14 text-dark mb-0 d-flex" style="gap: .5rem">
                                <span>{{ consult.expiredAt | moment('DD MMM YYYY') }}</span>
                                <span class="text-orange">{{ getDiff(consult.expiredAt) > 0 ? `(Tersisa ${getDiff(consult.expiredAt)} hari)` : '' }}</span>
                            </p>
                            <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
                        </div>
                        <div class="separator" />
                        <div class="mb-1">
                            <p class="f-12 font-400" style="margin-bottom: 4px">Konsul ID</p>
                            <p class="font-500 f-14 text-light-blue mb-0">{{ consult.consultId }}</p>
                        </div>
                        <div class="mb-1">
                            <p class="f-12 font-400" style="margin-bottom: 4px">Chat ID</p>
                            <p class="font-500 f-14 text-light-blue mb-0">{{ consult.id }}</p>
                        </div>
                        <div>
                            <p class="f-12 font-400" style="margin-bottom: 4px">Tipe</p>
                            <div class="d-flex flex-row flex-wrap" style="gap: 1rem">
                                <div
                                    v-for="(type, index) in types"
                                    :key="index"
                                    class="font-500 f-14 text-dark d-flex align-items-center"
                                    style="gap: .25rem"
                                >
                                    <img :src="getTypeIconUrl(type)" width="18" alt="" />
                                    <span>{{ type }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separator" />
                        <div class="mb-1">
                            <p class="f-12 font-400" style="margin-bottom: 4px">Dokter</p>
                            <p class="font-500 f-14 text-dark mb-0">{{ consult.doctor ? consult.doctor.fullName : '-' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </portal>

        <!-- Prescription -->  
        <portal to="portal2">
            <div
                class="position-fixed"
                :style="`top: 0; left: ${isPrescriptionOpen ? 0 : '100%'}; bottom: 0; right: 0; z-index: 2000; background-color: rgba(39, 42, 51, .3);`"
                v-if="consult"
                @click="closePrescription"
            >
                <div class="position-fixed bg-white pt-1 pr-1 pl-1 pb-0" :style="`top: 0; bottom: 0; right: 0; max-width: 75vw; width: ${prescriptionWrapperWidth}; transform: translateX(${isPrescriptionOpen ? 0 : prescriptionWrapperWidth}); transition: transform .2s ease-in;`" @click="(e) => e.stopPropagation()">
                    <tabs>
                        <tab title="Diagnosis & Resep">
                            <div>
                                <div v-if="channel.status != '2'">
                                    <ValidationObserver ref="observer">
                                        <ValidationProvider name="P" v-slot="{ errors }" rules="required" >
                                            <div class="d-flex flex-row mb-1" style="gap: 1rem">
                                                <div class="font-600" style="flex: 0 0 1rem">P</div>
                                                <div style="flex: 1">
                                                    <textarea name="" id="" cols="30" rows="4" class="form-control" v-model="consult.model.p"
                                                    placeholder="Ini adalah field catatan yang bisa diberikan oleh pihak clinic mengenai user"></textarea>
                                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <div class="d-flex flex-row mb-1" style="gap: 1rem">
                                            <div class="font-600" style="flex: 0 0 1rem">D</div>
                                            <div style="flex: 1">
                                                <ValidationProvider name="D" v-slot="{ errors }" rules="required" >
                                                    <multiselect 
                                                        v-model="consult.selectedDiagnoses"
                                                        label="diagnosis"
                                                        track-by="id" 
                                                        placeholder="Cari diagnosis" 
                                                        :options="diagnoses" 
                                                        :multiple="false" 
                                                        :searchable="true" 
                                                        :internal-search="true" 
                                                        :clear-on-select="false" 
                                                        :close-on-select="true"
                                                        @input="consult.model.d = consult.selectedDiagnoses.code">                                                    
                                                        <span slot="noResult">Oops! No diagnoses found.</span>
                                                    </multiselect>
                                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                </ValidationProvider>
                                                <ValidationProvider name="D Notes" v-slot="{ errors }" rules="required" >
                                                    <textarea name="" id="" cols="30" rows="4" class="form-control mt-1" v-model="consult.model.dNotes"
                                                    placeholder="Ini adalah field catatan yang bisa diberikan oleh pihak clinic mengenai user"></textarea>
                                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div v-if="channel.status != '1' && consult.products.filter(x=>x.consult_product.px == false).length > 0" class="d-flex flex-row mb-1" style="gap: 1rem">
                                            <div class="font-600 text-blue" style="flex: 0 0 1rem"></div>
                                            <div style="flex: 1">
                                                <p class="f-16 font-500 text-warning mb-0">Produk Reference by user </p>
                                                <div v-for="product in consult.products" :key="product.id">
                                                    <p class="f-14 text-black font-500 mb-0">{{ product.title }} 
                                                        <small>(Qty: {{ product.consult_product.qty }})</small></p>
                                                    <p class="f-12 font-300 mb-0 text-muted">{{ product.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row mb-1" style="gap: 1rem" v-else>
                                            <div class="font-600 text-blue" style="flex: 0 0 1rem">R/</div>
                                            <div style="flex: 1">
                                                <button class="btn btn-outline-primary w-100" @click="addResep()"><i class="fa fa-plus"></i> Tambah Resep</button>
                                            </div>
                                        </div>
                                        <div class="" v-for="(product, index) in consult.model.recipes" :key="index">
                                            <div class="d-flex flex-row mb-1" style="gap: 1rem">
                                                <div class="font-600 text-blue align-self-center" style="flex: 0 0 1rem">
                                                    <input type="checkbox" name="" id="" class="mb-1" v-model="consult.model.recipes[index].recommended"
                                                        title="Recommended">
                                                </div>
                                                <div style="flex: 1">
                                                    <div class="d-flex flex-row flex-wrap" style="gap: 1rem">
                                                        <div class="w-100">
                                                            <ValidationProvider :name="'Resep ' + (index+1)" v-slot="{ errors }" rules="required" >
                                                                <multiselect 
                                                                    v-model="consult.selectedProduct[index]"
                                                                    label="title"
                                                                    track-by="id" 
                                                                    placeholder="Cari obat" 
                                                                    :options="product_options.filter(x => x.stock > 0 && x.dummy == false)"
                                                                    :multiple="false" 
                                                                    :searchable="true" 
                                                                    :internal-search="true" 
                                                                    :clear-on-select="false" 
                                                                    :close-on-select="true"
                                                                    @input="$forceUpdate()">
                                                                    <span slot="noResult">Oops! No product found.</span>
                                                                </multiselect>
                                                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div style="flex: 1">
                                                            <ValidationProvider :name="'Jumlah ' + (index+1)" v-slot="{ errors }" :rules="{required: true, max_value_custom: consult.selectedProduct[index] ? consult.selectedProduct[index].stock : 0}" >
                                                                <input type="number" name="" id="" placeholder="Jumlah"  v-model="consult.model.recipes[index].usageAmount" class="form-control">
                                                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div style="flex: 1">
                                                            <ValidationProvider :name="'Dosis ' + (index+1)" v-slot="{ errors }" rules="required" >
                                                                <input type="text" class="form-control" placeholder="Dosis" v-model="consult.model.recipes[index].usageInterval">
                                                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-danger btn-sm mt-1 col" @click="deleteResep(index)">Hapus Resep</button>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>
                                        <ValidationProvider name="C" v-slot="{ errors }" rules="required" >
                                            <div class="d-flex flex-row mb-1" style="gap: 1rem">
                                                <div class="font-600" style="flex: 0 0 1rem">C</div>
                                                <div style="flex: 1">
                                                    <quill-editor 
                                                        id="catatan"
                                                        v-model="consult.model.c"
                                                        :options="editorOption1">
                                                    </quill-editor>
                                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <div class="d-flex flex-row mb-1" style="gap: 1rem">
                                            <div style="flex: 4">
                                                <ValidationProvider name="K" v-slot="{ errors }" rules="required" >
                                                    <div class="d-flex flex-row" style="gap: 1rem">
                                                        <div class="font-600" style="flex: 0 0 1rem">K</div>
                                                        <div class="k-date-form" style="flex: 1">
                                                            <div class="position-relative has-icon-right">
                                                                <date-picker
                                                                    v-model="consult.model.k"
                                                                    format="DD-MM-YYYY"
                                                                    type="date"
                                                                    input-class="form-control"
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="dd-mm-yy"
                                                                    style="width: 100%;"
                                                                    :disabled-date="restrictStartDate"
                                                                    :clearable="false"
                                                                >
                                                                </date-picker>
                                                            </div>                                                
                                                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div style="flex: 3">
                                                <ValidationProvider name="Iter" v-slot="{ errors }" rules="required">
                                                    <div class="d-flex flex-row" style="gap: 1rem">
                                                        <div class="font-600" style="flex: 0 0 1rem">Iter</div>
                                                        <div style="flex: 1">
                                                            <input type="number" class="form-control" placeholder="Iter" v-model.number="consult.model.iter">
                                                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <button class="btn btn-primary w-100 mb-1 rounded" :disabled="isLoading" @click="approve()" v-if="channel.status == '0'">
                                            <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                            <div v-else>Confirm & Approve</div>
                                        </button>
                                        <button class="btn btn-primary w-100 mb-1 rounded" :disabled="isLoading" @click="approve()" v-if="channel.status == '1'">
                                            <div v-if="isLoading">Submitting <i class="fa fa-spinner fa-spin"></i></div>
                                            <div v-else>Submit Diagnosis</div>
                                        </button>
                                        <button class="btn btn-outline-danger w-100 mb-1 rounded" @click="reject()" v-if="channel.status == '0'">Tolak / Not Approved</button>
                                    </ValidationObserver>
                                </div>
                                <p class="text-blue text-center">
                                    <router-link :to="'/konsultasi-dan-resep/' + consult.id">
                                        Rekam Medis <i class="fa fa-link"></i>
                                    </router-link>
                                </p>
                            </div>
                        </tab>
                        <tab title="Team">
                            <div>
                                <p class="font-500">Staff
                                    <button class="btn-sm btn-rounded btn-outline-primary pull-right" 
                                        @click="showAddStaffForm = true"
                                        v-if="showAddStaffForm == false">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </p>
                                <div class="text-center" v-if="showAddStaffForm == true">
                                    <div class="form-group">
                                        <multiselect 
                                            v-model="selectedAdmins"
                                            label="fullName"
                                            track-by="id" 
                                            placeholder="Cari staff" 
                                            :options="admins" 
                                            :multiple="true" 
                                            :searchable="true" 
                                            :internal-search="true" 
                                            :clear-on-select="false" 
                                            :close-on-select="false"
                                            @search-change="getAdmins">                                                    
                                            <span slot="noResult">Oops! No staff found.</span>
                                        </multiselect>
                                    </div>
                                    <button class="btn rounded mb-1 text-danger" @click="showAddStaffForm = false">Batal</button>
                                    <button class="btn btn-primary rounded mb-1" @click="addStaff()">Tambah Staff</button>
                                    <hr>
                                </div>
                                <div v-if="staffs.length > 0">
                                    <div class=""  v-for="(staff, index) in staffs" :key="index">
                                        <div class="row pl-1">
                                            <img :src="staff.avatar ? staff.avatar : 'assets/img/icon/profile.png'" alt="" style="
                                                width: 40px;
                                                height: 40px;
                                                border: 1px solid #ccc;
                                                border-radius: 8px;">
                                            <div class="col">
                                                <p class="mb-0 f-12 font-600">{{ staff.fullName }}</p>
                                                <p class="text-blue f-12 font-600 mb-0">{{ staff.role.name }}</p>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                                <p class="f-14 text-center" v-else>Tidak ada Staff</p>
                                <p class="font-500">Komentar</p>
                                <div class="form-group">
                                    <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Tinggalkan komentar" v-model="comment.body"></textarea>
                                </div>
                                <button class="btn btn-primary w-100 rounded mb-1" @click="addComment()">Beri Komentar</button>
                                <div class="p-1 mb-1" style="
                                        background: #7E8CB71A;
                                        border-radius: 16px;
                                    " v-for="(comment, index) in comments" :key="index">
                                    <p class="f-12">{{ comment.body }}</p>
                                    <p class="font-600 f-12 mb-0">{{ comment.admin.fullName }}</p>
                                    <p class="text-blue f-12 mb-0"><b>{{ comment.admin.role.name }}</b> 
                                        <span class="text-secondary f-12 ml-1">{{ comment.createdAt | moment('DD MMM YYYY')}} - {{ comment.createdAt | moment('HH:mm') }} WIB</span>
                                    </p>
                                </div>
                            </div>
                        </tab>
                    </tabs>
                </div>
            </div>
        </portal>
    </div>
</template>
<script>
import sanitizeHtml from 'sanitize-html';
import Tabs from '../components/Tabs/Tabs';
import Tab from '../components/Tabs/Tab';
import moment from 'moment';

const INFO_WIDTH = 290;
const PRESCRIPTION_WIDTH = 320;
const DEFAULT_K_DATE = moment().add(30, 'days').format('YYYY-MM-DD');
const DEFAULT_ITER = 3;

export default {
    components: {
        Tabs,
        Tab
    },
    data() {
        return {
            consults: [],
            consult: null,
            messages: [],
            search: '',
            id_user: '',
            activeChat: '',
            products: [],
            product_options: [],
            selectedProduct: [],
            staffs: [],
            staff: {
                adminIds: []
            },
            comments: [],
            comment: {
                body: ""
            },
            admins: [],
            selectedAdmins: [],
            showAddStaffForm: false,
            diagnoses: [],
            selectedDiagnoses: [],
            filterActive: 'semua',
            status: '',
            search_message: '',
            channel: null,
            uploadedImages: [],
            max_file_size: 4,
            types: [],
            fullName: '',
            temporaryDoctor: '',
            isLoading: false,
            channels: [],
            // all_consults: [],
            editorOption: {
                modules: {
                    toolbar: false
                },
                placeholder:"Halo, saya dokter (nama)..."
            },
            editorOption1: {
                modules: {
                    toolbar: false
                },
                placeholder:"Catatan plan disini"
            },
            page: 1,
            lastPage: 1,
            infoWrapperWidth: `${INFO_WIDTH}px`,
            isInfoOpen: false,
            prescriptionWrapperWidth: `${PRESCRIPTION_WIDTH}px`,
            isPrescriptionOpen: false,
            isNavbarShow: true,
        }
    },
    beforeDestroy() {
        this.$emit('show-mobile-navbar');
        this.isNavbarShow = true;
        window.Pusher.unsubscribe('private-clinical')
    },
    methods: {
        getDiff(x)
        {
            let _ = this
            let expiredAt = _.$moment(x).format('YYYY-MM-DD') + ' 00:00:00'
            let now = _.$moment().format('YYYY-MM-DD') + ' 00:00:00'

            return (_.$moment(expiredAt).diff(_.$moment(now), 'days'))
        },
        getChannels()
        {
            let _ = this

            // _.consults = []
            // _.all_consults = []
            _.axios.get('/channels' + '?limit=100&sortBy=updatedAt.DESC' 
                + (_.status != '' ? '&status=' + _.status : '' )
                + (_.search_message != '' ? '&search=' + _.search_message : '')
                + (_.page != '' ? '&page=' + _.page : '') 
                )
                .then(resp=> {
                    let data = resp.data.data.rows
                    _.lastPage = resp.data.data.totalPages
                    _.channels = data
                    for (let i = 0; i < data.length; i++) {
                        _.consults.push(data[i])
                        // _.all_consults.push(data[i])
                    }
                    if ( _.$route.query.chat ) {
                        let hehe = _.consults.filter( x => x.consultId == parseInt(_.$route.query.chat))[0]
                        _.subscribe(hehe)
                    } else if (_.consults.length > 0) {
                        if (_.status != '2') {
                            _.consults = _.consults.filter(x => x.status != '2')
                        }
                    }
                })

        },
        subscribe(data)
        {
            let _ = this

            if (_.consult && data.consult.id == _.consult.id) {
                return;
            }
        
            _.channel = data
            _.consult = { ...data.consult, consultationStatus: data.status }
            _.activeChat = data.id
            this.$emit('hide-mobile-navbar');
            this.isNavbarShow = false;
            this.$router.replace({ query: { chat: data.consult.id } });

            if ( _.consult.form ) {
                _.consult.form = {
                    message: _.consult.form.message ? _.consult.form.message : '',
                    channelName: data.name,
                    attachment: _.consult.form.attachment.length > 0 ? _.consult.form.attachment : [],
                }
                _.$forceUpdate()
            } else {
                _.consult.form = {
                    message: '',
                    channelName: data.name,
                    attachment: [],
                }
                _.$forceUpdate()
            }

            if ( !_.consult.model ) {
                _.consult.model = {
                    consultId: _.consult.id,
                    recipes: [],
                    p: '',
                    d: '',
                    dNotes: '',
                    c: '',
                    k: DEFAULT_K_DATE,
                    iter: DEFAULT_ITER,
                }
                _.$forceUpdate()
            }

            if ( !_.consult.selectedDiagnoses ) {
                _.consult.selectedDiagnoses = []
                _.$forceUpdate()
            }

            if ( !_.consult.selectedProduct ) {
                _.consult.selectedProduct = []
                _.$forceUpdate()
            }
            
            _.temporaryDoctor = ''

            _.types = []
            if ( _.consult.orders && _.consult.orders.length > 0 ) {
                _.consult.orders.forEach(order => {
                    order.types.name.forEach(type => {
                        _.types.push(type)
                    })
                });
            }

            _.getMessage(data.id)
            _.getStaffs()
            _.getComments()
            _.resetReadCount()
        },
        getMessage(id)
        {
            let _ = this
            _.axios.get('/channels/' + id + '/messages')
                .then(resp=>{
                    _.messages = []

                    let data = resp.data.data
                    for (let i = 0; i < data.length; i++) {
                        _.messages.push(data[i])
                    }

                    setTimeout(() => {
                        var elm = this.$el.querySelector('#chat-window')
                        elm.scrollTop = elm.scrollHeight
                    })
                })
        },
        sendMessage()
        {
            let _ = this

            _.$refs.inputChat.quill.focus();
            if (_.consult.form.attachment.length == 0 && _.consult.form.message == '') {
                _.$notify({
                    type: 'error',
                    title: 'Gagal mengirim pesan',
                    text: 'Pesan tidak boleh kosong'
                })
                return;
            }

            let FData = new FormData()

            FData.append('message', sanitizeHtml(_.consult.form.message))
            FData.append('channelName', _.consult.form.channelName)

            for (let index = 0; index < _.consult.form.attachment.length; index++) {
                FData.append('attachment', _.consult.form.attachment[index])
            }

            _.axios.post('/chat/send', FData)
                .then(resp=>{
                    console.log(resp.data)
                    _.consult.form.message = ''
                    _.consult.form.attachment = []
                    _.uploadedImages = []
                })
                .catch( err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    })
                })
        },
        append(emoji) {
            const editor = this.$refs.inputChat.quill;
            const cursorPosition = editor.getSelection(true);
            editor.insertText(cursorPosition, emoji);
        },
        getAge(birthday) {
            if(birthday){
                var totalMonths = this.$moment().diff(birthday, 'months');
                var years = parseInt(totalMonths / 12);
                var months = totalMonths % 12;

                if( months !== 0 ){
                    return years + ' tahun ' + months + ' bulan';
                }
                return years + ' tahun';
            }
            return null;
        },
        addResep()
        {
            let _ = this
            _.consult.model.recipes.push({
                productId: '',
                usageAmount: '',
                usageInterval: ''
            })
            _.$forceUpdate()
        },
        getProducts()
        {
            let _ = this
            _.axios.get('products')
                .then(resp=>{
                    _.product_options = resp.data.data.rows
                })
        },
        onChange( event, idx )
        {
            let _ = this
            let data = event.target.value
            let content = _.product_options.filter(x => x.id == data)[0]

            _.consult.model.productIds.push(event.target.value)
            _.products[idx].jumlah = content.measure
            _.products[idx].dosis = content.measure
        },
        getStaffs()
        {
            let _ = this
            _.axios.get('/consults/' + _.consult.id + '/staffs')
                .then(resp => {
                    _.staffs = resp.data.data.rows
                })
        },
        addStaff()
        {
            let _ = this
            
            _.selectedAdmins.forEach(elm => {
                _.staff.adminIds.push(elm.id)
            });

            _.axios.post('/consults/' + _.consult.id + '/staffs', _.staff)
                .then(resp => {
                    console.log(resp.data)
                    _.staff = {
                        adminIds: []
                    }
                    _.selectedAdmins = []
                    _.getStaffs()
                    _.showAddStaffForm = false
                })
        },
        getComments()
        {
            let _ = this
            _.axios.get('/consults/' + _.consult.id + '/comments')
                .then(resp => {
                    _.comments = resp.data.data
                })
        },
        addComment()
        {
            let _ = this
            
            if (_.comment.body == ""){
                _.$notify({
                    type: 'error',
                    title: 'Gagal mengirim komentar',
                    text: 'Komentar tidak boleh kosong'
                })
                return;
            } 

            _.axios.post('/consults/' + _.consult.id + '/comments', _.comment)
                .then(resp => {
                    console.log(resp.data.data)
                    _.comment = {
                        body: ""
                    }
                    _.getComments()
                })
        },
        getAdmins(key)
        {
            let _ = this
            _.axios.get('/admins' + ( key ? '?search=' + key : '' ))
                .then(resp => {
                    _.admins = resp.data.data.rows
                })
        },
        getDiagnosis()
        {
            let _ = this
            _.axios.get('/misc/icd10cm')
                .then(resp => {
                    _.diagnoses = resp.data.data
                })
        },
        async approve()
        {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid) {
                this.isLoading = true
                for (let i = 0; i < _.consult.selectedProduct.length; i++) {
                    _.consult.model.recipes[i].productId = _.consult.selectedProduct[i].id
                }

                _.axios.post('/diagnoses', _.consult.model)
                    .then(resp => {
                        if ( resp.data.data.consult.orders[0].type == 3) {
                            _.channel.status = 1
                            _.consult.consultationStatus = 1
                        }

                        _.$toast.open({
                            message: "Diagnosis & Resep Submitted",
                            type: "info",
                            duration: 5000,
                            dismissible: true
                        })

                        _.consult.selectedDiagnoses = []
                        _.consult.selectedProduct = []
                        _.consult.model = {
                            // consultId: '',
                            recipes: [],
                            p: '',
                            d: '',
                            dNotes: '',
                            c: '',
                            k: DEFAULT_K_DATE,
                            iter: DEFAULT_ITER,
                        }
                        _.$forceUpdate()

                        _.temporaryDoctor = _.fullName
                        _.consult.activationDate = resp.data.data.consult.activationDate
                        _.consult.expiredAt = resp.data.data.consult.expiredAt
                        _.consult.model.consultId = resp.data.data.consultId

                        _.channel.consult.activationDate = resp.data.data.consult.activationDate

                        _.$forceUpdate()
                        this.isLoading = false

                        if (_.filterActive != 'semua') {
                            if ( resp.data.data.consult.orders[0].type == 3 ) {
                                _.filterActive = 'aktif'
                            } else if ( resp.data.data.consult.activationDate ) {
                                _.filterActive = 'review-to'
                            } else {
                                _.filterActive = 'semua'
                            }
                        }

                        this.$nextTick(() => this.$refs.observer.reset())
                    })
                    .catch( err => {
                        _.$notify({
                            type: 'error',
                            title: err.response.data.message,
                            text: err.response.data.data[0].message
                        })

                        _.consult.selectedDiagnoses = []
                        _.consult.selectedProduct = []
                        _.consult.model = {
                            // consultId: '',
                            recipes: [],
                            p: '',
                            d: '',
                            dNotes: '',
                            c: '',
                            k: DEFAULT_K_DATE,
                            iter: DEFAULT_ITER,
                        }
                        _.$forceUpdate()
                        this.isLoading = false

                        this.$nextTick(() => this.$refs.observer.reset())
                    })
            }
        },    
        reject()
        {
            let _ = this
            _.axios.patch('/diagnoses/reject/' + _.consult.id)
                .then(resp => {
                    console.log(resp.data)
                    _.channel.status = 2
                    _.consult.consultationStatus = 2

                    _.consult.selectedDiagnoses = []
                    _.consult.selectedProduct = []

                    _.consult.model = {
                        consultId: '',
                        recipes: [],
                        p: '',
                        d: '',
                        dNotes: '',
                        c: '',
                        k: DEFAULT_K_DATE,
                        iter: DEFAULT_ITER,
                    }

                    let searchIndex = _.consults.map( x => x.consultId ).indexOf( _.consult.id )
                    if (searchIndex != -1) {
                        _.consults.splice(searchIndex, 1)
                        _.$forceUpdate()
                    }

                    _.temporaryDoctor = _.fullName

                    _.filterActive = 'berakhir'
                })
        },
        consultColor(x, activationDate)
        {
            let color = ''
            switch (x) {
                case 0:
                    if (activationDate) {
                        color = 'btn-review-to'
                    } else {
                        color = 'btn-warning'
                    }
                    break;
                case 1:
                    color = 'btn-success'
                    break;
                case 2:
                    color = 'btn-danger'
                    break;
            
                default:
                    color = 'btn-primary'
                    break;
            }

            return color;
        },
        consultStatus(x, activationDate)
        {
            let name = ''
            switch (x) {
                case 0:
                    if (activationDate) {
                        name = 'Review TO'
                    } else {
                        name = 'Review'
                    }
                    break;
                case 1:
                    name = 'Aktif'
                    break;
                case 2:
                    name = 'Berakhir'
                    break;
            
                default:
                    name = '-'
                    break;
            }

            return name;
        },
        handleFileUpload(event){
            this.file = event.target.files[0]
            if (this.file.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: 'The image file is too large, allowed maximum size is '+ this.max_file_size +'MiB'
                })
                return;
            }

            this.consult.form.attachment.push(event.target.files[0])
            this.uploadedImages.push(URL.createObjectURL(this.file))
        },
        deleteFile (id){
            this.consult.form.attachment.splice( id, 1 )
            this.uploadedImages.splice( id, 1 )
        },
        deleteResep(x){
            this.consult.model.recipes.splice(x, 1)
            this.consult.selectedProduct.splice(x, 1)

            this.$forceUpdate()
        },
        restrictStartDate(date) {
			return date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
		},
        resetReadCount() {
            let _ = this
            _.axios.post('/chat/reset-read-count', { channelName: _.channel.name })
                .then( resp => {
                    console.log(resp.data)
                    _.channel.unreadAdminCount = 0
                    _.channel.is_new = false
                })
        },
        html_striptag(str) {
            return str.replaceAll('</p><p>', ' ').replace(/(<([^>]+)>)/gi, "")
        },
        downloadConsult(url) {
            fetch(url, { headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken')}})
                .then(res => res.blob())
                .then(blob => {
                    let link        = document.createElement('a')
                    link.href       = window.URL.createObjectURL(blob)
                    link.download   = this.consult.consultId + '.pdf'
                    link.click()
                })
        },
        is_closed( consult ) {
            let _ = this
            if (!consult.closedAt && _.$moment() > _.$moment(consult.expiredAt)) {
                return true
            } else if (consult.closedAt && _.$moment() > _.$moment(consult.closedAt)) {
                return true
            }

            return false
        },
        getTypeIconUrl(type) {
            switch (type) {
                case 'Belanja':
                    return '/assets/img/icon/shop-icon.svg';
                case 'Konsultasi':
                    return '/assets/img/icon/consult-icon.svg';
                default:
                    return '/assets/img/icon/consult-icon.svg';
            }
        },
        openInfo() {
            this.isInfoOpen = true;
        },
        closeInfo() {
            this.isInfoOpen = false;
        },
        openPrescription() {
            this.isPrescriptionOpen = true;
            this.getProducts()
        },
        closePrescription() {
            this.isPrescriptionOpen = false;
        },
        closeChatRoom() {
            this.consult = null;
            this.activeChat = '';
            this.$router.replace({ query: null });
            this.$emit('show-mobile-navbar');
            this.isNavbarShow = true;
        },
        getConsultationDetail( x ) {
            let _ = this

            _.axios.get('/channels/' + x.id)
                .then(resp => {
                    let e = resp.data.data
                    if (e.consult) {
                        let consultId = e.consultId
                        let searchConsult = _.consults.filter(x => x.consultId === consultId)
                        if ( searchConsult.length == 0 ) {
                            if (e.status == 0) {
                                if (_.filterActive == 'review') {
                                    if (!e.activationDate) {
                                        e.is_new = true
                                        _.channels.push(e)
                                        _.consults.push(e)
                                        _.consults.sort(function(x,y){ return x.consultId == consultId ? -1 : y == consultId ? 1 : 0; });
                                        _.$forceUpdate()
                                    }
                                } else if (_.filterActive == 'review-to') {
                                    if (e.activationDate) {
                                        e.is_new = true
                                        _.channels.push(e)
                                        _.consults.push(e)
                                        _.consults.sort(function(x,y){ return x.consultId == consultId ? -1 : y == consultId ? 1 : 0; });
                                        _.$forceUpdate()
                                    }
                                } else if (_.filterActive == 'semua'){
                                    e.is_new = true
                                    _.channels.push(e)
                                    _.consults.push(e)
                                    _.consults.sort(function(x,y){ return x.consultId == consultId ? -1 : y == consultId ? 1 : 0; });
                                    _.$forceUpdate()
                                }
                            }
                        }
                    }
                })
        }
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    },
    mounted() {
        let _ = this
        
        let user = JSON.parse(localStorage.getItem('account'))
        _.id_user = user.id
        _.fullName = user.admin.fullName

        this.$emit('show-mobile-navbar');
        this.isNavbarShow = true;

        _.getChannels()
        _.getProducts()
        _.getAdmins()
        _.getDiagnosis()

        if (window.innerWidth >= 768) {
            this.editorOption.modules.keyboard = {
                bindings: {
                    enter: {
                        key: 13,
                        handler: function() {
                            _.sendMessage()
                            this.hideSymbols = !this.hideSymbols;
                        }
                    }
                }
            }
        }

        window.Pusher.unsubscribe('private-clinical')
        window.Pusher.subscribe('private-clinical')
            .bind('MESSAGE_RECEIVED', (e) => {
                let consultId = e.consultId
                
                if ( _.channel != null && consultId == _.channel.consultId ) {
                    _.messages.push(e)
                    _.channel.lastMessage = e
                    _.channel.updatedAt = e.timestamp

                    setTimeout(() => {
                        var elm = this.$el.querySelector('#chat-window')
                        elm.scrollTop = elm.scrollHeight
                    })
                    setTimeout(() => {
                        var elm = this.$el.querySelector('#chat-list')
                        elm.scrollTop = -elm.scrollHeight
                    })
                } else {
                    let searchConsult = _.consults.filter(x => x.consultId === consultId)[0]
                    searchConsult.lastMessage = e
                    searchConsult.updatedAt = e.timestamp
                    searchConsult.unreadAdminCount += 1
                }

                _.consults.sort(function(x,y){ return x.consultId == consultId ? -1 : y == consultId ? 1 : 0; });
            });
        window.Pusher.subscribe('private-clinical')
            .bind('CHANNEL_CREATED', function(e) {
                _.getConsultationDetail(e)
            });
        window.Pusher.subscribe('private-clinical')
            .bind('CHANNEL_ACTIVATED', function(e) {
                let consultId = e.consultId

                _.axios.get('/channels/' + e.id)
                    .then(resp => {
                        let data = resp.data.data
                        if (data.consult) {
                            let expiredAt = data.consult.expiredAt

                            if ( _.consult.id == e.consultId ) {
                                _.consult.consultationStatus = 1
                                _.consult.status = 1
                                _.consult.expiredAt = expiredAt
                                
                                _.$forceUpdate()
                            }

                            let searchConsult = _.consults.filter(x => x.consultId === consultId)[0]
                            searchConsult.status = 1
                            searchConsult.expiredAt = expiredAt
                        
                            _.consults.sort(function(x,y){ return x.consultId == consultId ? -1 : y == consultId ? 1 : 0; });
                        }
                    })
            });
    },
    watch: {
        filterActive: function(newValue) {
            let _ = this
            this.consults = []
            _.page = 1
            if ( newValue == 'semua' ) {
                _.status = ''
            } else if ( newValue == 'aktif' ) {
                _.status = '1'
            } else if ( newValue == 'review' ) {
                _.status = '0'
            } else if ( newValue == 'review-to' ) {
                _.status = '4'
            } else if ( newValue == 'berakhir' ) {
                _.status = '2'
            }
            _.getChannels()
        },
        search_message: function() {
            // this.status = ''
            this.page = 1
            this.consults = []
            this.getChannels()
        },
        page: function() {
            this.getChannels()
        }
    }
}
</script>
<style>
.wrapper {
    height: calc(100vh - 69px);
}

.wrapper__hidden-navbar {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

@media (min-width: 768px) {
    .wrapper__hidden-navbar {
        height: calc(100vh - 69px);
    }
}
.emoji-picker {
    position: absolute;
    z-index: 1;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 17rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
    display: flex;
}
.emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
}
.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
}
.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}
.emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
}
.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}
.chat.active {
    background: #F2F2F6;
    border-bottom: 0px;
}
.separator {
    border-top: 1px solid #BCC0D1;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.k-date-form .mx-icon-calendar {
    display: none;
}
.k-date-form .position-relative .form-control {
    padding-right: 14px;
}
.btn-review-to{
    background-color:#ddd50b !important;
    color: #fff;
}
</style>